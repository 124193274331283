<div class="custom-header">
  <div class="button" (click)="previousClicked('month')">
    <img src="assets/img/icons/arrow-left.svg"/>
  </div>
  <div class="custom-header-label">
    <p>Sale Date</p>
    <h4>{{ periodLabel | date: 'MMMM' }}</h4>
    <h5>{{ periodLabel | date: 'yyyy' }}</h5>
  </div>
  <div class="button" (click)="nextClicked('month')">
      <img src="assets/img/icons/arrow-right.svg"/>
  </div>
</div>

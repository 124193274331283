<div class="row-container"
     [ngClass]="{ 'disabled': enabled === false }">
  <div>{{ position }}</div>
  <div>{{ lotNumber | removeLeadingZeroes }}</div>
  <div [class]="brokerId">
    <img *ngIf="imageShowing" [src]="brokerImage" (loaded)="showImage()" (error)="hideImage()"/>
    <h3 *ngIf="!imageShowing">{{originalSellingOrganisation}}</h3>
  </div>
  <div>
    <input
      disabled
      appUppercase
      appFocus
      [ngClass]="cssClass"
      [value]="inputCode"
      [isFocused]="selected"
      (focusout)="onInputFocusOut($event)"
      (focus)="onInputFocus($event)"
      #transactionCodeInput
      type="text"
      (keyup.enter)="onTransactionCodeEntered(transactionCodeInput.value)"
    />
  </div>
  <div>{{ price }} </div>
  <div>{{ status }}</div>
  <div>{{ buyer }}</div>
  <div>{{ woolTypeGroup }}</div>
  <div></div>
</div>

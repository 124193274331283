import { Action } from '@ngrx/store'
import { SaleFormData } from '@shared/models/sale-form-data.model'
import { SaleForm } from '@shared/models/sale-form.model'
import { CreateSale } from '@shared/services/request-models/create-sale.model'

export enum SaleFormActionTypes {
  SaleFormAddSale = '[SALE FORM] New Sale',
  SaleFormCancelSale = '[SALE FORM] Cancel Sale',
  SaleFormCreateSale = '[SALE FORM] Create Sale',
  SaleFormEmitSaleError = '[SALE FORM] Emit Sale Error',
  SaleFormVerifySale = '[SALE FORM] Verify Sale',
  SaleFormPollStatus = '[SALE FORM] Poll Creation Status',
  SaleFormReEnterSale = '[SALE FORM] Re-enter Sale',
  SaleFormCleared = '[SALE FORM] Cleared',
  SaleFormOverwrite = '[SALE FORM] Overwrite',
  SaleFormStarted = '[SALE FORM] Started'
}

export class SaleFormAddSale implements Action {
  readonly type = SaleFormActionTypes.SaleFormAddSale
}

export class SaleFormCancelSale implements Action {
  readonly type = SaleFormActionTypes.SaleFormCancelSale
}

export class SaleFormCreateSale implements Action {
  readonly type = SaleFormActionTypes.SaleFormCreateSale

  constructor (public payload: CreateSale) {

  }
}

export class SaleFormEmitSaleError implements Action {
  readonly type = SaleFormActionTypes.SaleFormEmitSaleError

  constructor (public error: string) {

  }
}

export class SaleFormPollStatus implements Action {
  readonly type = SaleFormActionTypes.SaleFormPollStatus

  constructor (public id: number) {

  }
}

export class SaleFormVerifySale implements Action {
  readonly type = SaleFormActionTypes.SaleFormVerifySale
}

export class SaleFormReEnterSale implements Action {
  readonly type = SaleFormActionTypes.SaleFormReEnterSale
}

export class SaleFormCleared implements Action {
  readonly type = SaleFormActionTypes.SaleFormCleared
}

export class SaleFormOverwrite implements Action {
  readonly type = SaleFormActionTypes.SaleFormOverwrite
}

export class SaleFormStarted implements Action {
  readonly type = SaleFormActionTypes.SaleFormStarted
}

export type SaleFormActions =
| SaleFormAddSale
| SaleFormCancelSale
| SaleFormCreateSale
| SaleFormEmitSaleError
| SaleFormVerifySale
| SaleFormReEnterSale
| SaleFormCleared
| SaleFormOverwrite
| SaleFormStarted

<div class="header-container">
  <div class="header-content">
    <div class="title">
      <h1><a [routerLink]="['/']">AWH Sale Roster</a></h1>
    </div>
    <div class="actions">
      <button class="toggle-search"
              (click)="toggleSearch()">
        <div class="show"
             *ngIf="!(searchVisible$ | async) && router.url === '/'">Show Filters</div>
        <div class="hide"
             *ngIf="(searchVisible$ | async) && router.url === '/'">Hide Filters</div>
      </button>
      <button class="add-sale"
              (click)="addSale()">
        <div class="add"><img src="assets/img/icons/add.svg"> Add Sale</div>
      </button>
      <button class="edit-org"
              (click)="editOrg()">
        <div class="edit">Add/Edit Org.</div>
      </button>
      <button class="logout"
              (click)="logout()">
        <div class="logout">Logout</div>
      </button>
    </div>
  </div>
</div>

import { Component, OnInit, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent implements OnInit {
  tableView = 'brokers'
  @Output() toggleView = new EventEmitter()
  constructor() { }

  ngOnInit() {
  }

  switchView(toggleIndex) {
    const views = [ 'lots', 'brokers' ]
    this.tableView = views[toggleIndex]
    this.toggleView.emit(this.tableView)
  }

}

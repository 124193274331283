<div class="login-container">
  <div class="title">
    <h2>AWH WoolEx Sale Roster</h2>
  </div>
  <div class="content">
    <form [formGroup]="form" class="login-form">
      <input
        type="text"
        placeholder="Username"
        formControlName="username"
      />
      <input 
        type="password"
        placeholder="Password"
        formControlName="password"
      />
      <button 
        (click)="login()"
        [disabled]="!form.valid"
      >Login</button>
    </form>
  </div>
</div>

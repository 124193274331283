import { Lot } from '@shared/models/lot.model'
import { Transaction } from '@shared/models/transaction.model'

import {
  SaleRoomBrokerReorderCancelled,
  SaleRoomBrokerReordered,
  SaleRoomBrokerReorderRequested,
  SaleRoomDataActionTypes,
  SaleRoomDataCancelled,
  SaleRoomDataCleared,
  SaleRoomDataLoaded,
  SaleRoomDataLotsLoaded,
  SaleRoomDataLotsRequested,
  SaleRoomDataRequested,
  SaleRoomLotReorderCancelled,
  SaleRoomLotReordered,
  SaleRoomLotReorderRequested,
  SaleRoomLotsMoveCancelled,
  SaleRoomLotsMoved,
  SaleRoomLotsMoveRequested,
  SaleRoomDataDeleted,
} from '../actions/sale-room-data.actions'

export interface SaleRoomDataError {
  hasError: boolean
  message: string
}

export interface SaleRoomDataState {
  loaded: boolean
  loading: boolean
  error: SaleRoomDataError
  roomNumber: number
  roomCount: number
  saleId: string
  locationId: string
  saleNo: number
  season: number
  startTime: string
  lots: Lot[]
  transactions: Transaction[]
}

export const initialState: SaleRoomDataState = {
  loaded: false,
  loading: false,
  error: {
    hasError: false,
    message: '',
  },
  roomNumber: null,
  roomCount: 0,
  saleId: '',
  locationId: null,
  saleNo: null,
  season: null,
  startTime: '',
  lots: [],
  transactions: []
}

export function saleRoomDataReducer (
  state = initialState,
  action:
    | SaleRoomDataRequested
    | SaleRoomDataLoaded
    | SaleRoomDataCleared
    | SaleRoomDataCancelled
    | SaleRoomDataCleared
    | SaleRoomDataLotsRequested
    | SaleRoomDataLotsLoaded
    | SaleRoomLotsMoveRequested
    | SaleRoomLotsMoved
    | SaleRoomLotsMoveCancelled
    | SaleRoomBrokerReorderRequested
    | SaleRoomBrokerReordered
    | SaleRoomBrokerReorderCancelled
    | SaleRoomLotReorderRequested
    | SaleRoomLotReordered
    | SaleRoomLotReorderCancelled
    | SaleRoomDataDeleted

): SaleRoomDataState {

  if (!action || !action.type) {
    return initialState
  }

  switch (action.type) {
    case SaleRoomDataActionTypes.SaleRoomDataRequested:
      return {
        ...state,
        loaded: false,
        loading: true,
      }

    case SaleRoomDataActionTypes.SaleRoomDataLoaded:
      return {
        loaded: true,
        loading: false,
        error: {
          hasError: false,
          message: '',
        },
        roomNumber: action.payload.SaleRoomData.roomNumber,
        roomCount: action.payload.SaleRoomData.roomCount,
        saleId: action.payload.SaleRoomData.saleId,
        saleNo: action.payload.SaleRoomData.saleNo,
        season: action.payload.SaleRoomData.season,
        locationId: action.payload.SaleRoomData.locationId,
        startTime: action.payload.SaleRoomData.startTime,
        lots: action.payload.SaleRoomData.lots,
        transactions: action.payload.SaleRoomData.transactions
      }

    case SaleRoomDataActionTypes.SaleRoomDataCleared:
      return {
        ...initialState,
      }

    case SaleRoomDataActionTypes.SaleRoomDataCancelled:
      const error = {
        hasError: action.payload.error.hasError,
        message: action.payload.error.message,
      }

      return {
        ...state,
        loading: false,
        loaded: false,
        error,
      }

    case SaleRoomDataActionTypes.SaleRoomDataLotsLoaded:
      return {
        ...state,
        lots: action.payload.lots,
      }

    case SaleRoomDataActionTypes.SaleRoomDataDeleted:
      return {
        ...state,
        saleId: action.payload.id,
      }

    default:
      return state
  }
}

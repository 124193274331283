import { Action } from '@ngrx/store'
import { CreateTransaction } from '@shared/models/create-transaction.model'

export enum TransactionActionTypes {
  AddTransaction = '[TRANSACTIONS] Add Transaction'
}

export interface AddTransactionModel {
  saleId: string
  roomNumber: number
  transaction: CreateTransaction
}

export class AddTransaction implements Action {
  readonly type = TransactionActionTypes.AddTransaction

  constructor (public transactions: AddTransactionModel[]) {

  }
}


export type TransactionActions = AddTransaction

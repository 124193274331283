import { Sale } from '@shared/models/sale.model'
import * as faker from 'faker'

export const sales: Sale[] = [
  {
    canDelete: false,
    id: 'M42',
    locationName: 'M',
    locationId: 'M',
    season: 18,
    saleNo: 19,
    startTime: new Date().toISOString().split('T')[0],
    startTime2: new Date().toISOString().split('T')[0],
    rooms: [
      {
        brokers: 5,
        lots: 40,
        bales: 800
      },
      {
        brokers: 3,
        lots: 20,
        bales: 340
      }
    ]
  },
  {
    canDelete: false,
    id: 'F21',
    locationName: 'F',
    locationId: 'F',
    season: 18,
    saleNo: 19,
    startTime: new Date().toISOString().split('T')[0],
    startTime2: new Date().toISOString().split('T')[0],
    rooms: [
      {
        brokers: 6,
        lots: 25,
        bales: 985
      },
      {
        brokers: 15,
        lots: 34,
        bales: 1243
      }
    ]
  },
]

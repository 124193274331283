<div class="room-summary" (click)="emitViewRoom()">
  <div class="title-block" (click)="$event.stopPropagation(); $event.stopImmediatePropagation()">
    <h3 (click)="emitViewRoom()"><span *ngIf="locationId && saleNo">{{ locationId }}{{ saleNo }} -</span> ROOM
      {{ roomNumber }}
    </h3>
    <input
      [ngxTimepicker]="timeEditor"
      [(ngModel)]="time"
      readonly
    >
    <ngx-material-timepicker
      #timeEditor
      [ngxMaterialTimepickerTheme]="pickerTheme"
      [defaultTime]="startTime"
      (timeSet)="setTime($event)"
    ></ngx-material-timepicker>
  </div>
  <div class="brokers">
    <h3>{{ saleRoomSummary.brokers }}</h3>
    <p>Brokers</p>
  </div>
  <div class="lots">
    <h3>{{ saleRoomSummary.lots }}</h3>
    <p>Lots</p>
  </div>
  <div class="bales">
    <h3>{{ saleRoomSummary.bales }}</h3>
    <p>Bales</p>
  </div>
</div>

import * as SaleFormActions from './actions/sale-form.actions'
import * as SaleDataActions from './actions/sale-room-data.actions'
import * as DataFetchActions from './actions/data-fetch.actions'
import * as AuthActions from './actions/auth.actions'

export const Actions = {
  SaleFormActions,
  SaleDataActions,
  DataFetchActions,
  AuthActions
}

import { Component } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { noop, Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { Error } from '@shared/models/data-fetch.model'

import { AppState } from '@store/store.interface'
import { Login } from '@store/actions/auth.actions'
import { AuthService } from '@shared/services/auth/auth.service'

import {
  ShowError,
  ShowLoading,
  Dismiss
} from '@store/actions/data-fetch.actions'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  locationName$: Observable<string>
  roomName$: Observable<string>
  form: UntypedFormGroup

  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private router: Router,
    private store: Store<AppState>,
  ) {
    this.form = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    })
  }

  login() {
    const formValue = this.form.value
    this.showLoader()
    this.auth
      .login(formValue.username, formValue.password)
      .pipe(
        tap(token => {
          this.dismissLoader()
          this.store.dispatch(new Login({ token }))
          this.router.navigateByUrl('')
        }),
      )
      .subscribe(() => noop, (error) => {
        if (error.status === 401 || error.status === 403) {
          this.showError({
            hasError: true,
            message: `Could not log you in: Please enter a valid username and password.`
          })
        } else {
          this.showError({
            hasError: true,
            message: `Could not log you in: ${error.message}`
          })
        }
        }
      )
  }

  showLoader () {
    this.store.dispatch(new ShowLoading('Logging in...'))
  }

  showError (error: Error) {
    this.store.dispatch(new ShowError(error))
  }

  dismissLoader () {
    this.store.dispatch(new Dismiss())
  }
}

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { Actions } from '@store/actions'
import { AppState } from '@store/store.interface'
import { throwError } from 'rxjs'
import { Observable } from 'rxjs/Observable'
import { catchError } from 'rxjs/operators'

import { AuthService } from '../services/auth/auth.service'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService, private store: Store<AppState>) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.auth.getToken()
    const auth = token ? `Bearer ${token}` : ''
    request = request.clone({
      setHeaders: {
        Authorization: auth
      }
    })

    return next.handle(request).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.store.dispatch(new Actions.AuthActions.Logout())
            this.store.dispatch(new Actions.DataFetchActions.ShowError({
              hasError: true,
              message: 'Your login has expired. Please log in again'
            }))
          }
        }
        return throwError(err)
      })
    )
  }
}

<div class="sale-room-container">
  <app-room-summary-content *ngIf="saleRoomSummary"
                            [saleRoomSummary]="saleRoomSummary"
                            [roomNumber]="roomNumber"
                            [date]="startTime"
                            [saleId]="saleId"
                            [locationId]="(saleRoomData$ | async)?.locationId"
                            [saleNo]="(saleRoomData$ | async)?.saleNo"
                            ></app-room-summary-content>
  <div class="lot-management-fields">
    <div class="range-selector lot-filter"
         *ngIf="tableView === 'lots'">
      <div class="input-fields-container">
        <input type="text"
               placeholder="Broker"
               (keyup.enter)="onFilter()"
               [(ngModel)]="brokerId" />
        <input type="text"
               placeholder="Lot #"
               (keyup.enter)="onFilter()"
               [(ngModel)]="lotNumber" />
        <button (click)="onFilter()">Filter</button>
        <button (click)="onClearFilter()">Clear</button>
      </div>
    </div>
    <div class="range-selector move"
         *ngIf="tableView === 'lots'">
      <div hidden>Select range <img src="assets/img/icons/arrow-down.svg" /></div>
      <div *ngIf="(saleRoomData$ | async)?.roomCount >= 2"
           class="input-fields-container">
        <input type="text"
               [(ngModel)]="fromLotMove" />
        <span>to</span>
        <input type="text"
               [(ngModel)]="toLotMove" />
        <button (click)="moveLots(fromLotMove, toLotMove)">Move</button>
      </div>
    </div>
    <div class="range-selector reorder"
         *ngIf="tableView === 'lots'">
      <div hidden>Select range <img src="assets/img/icons/arrow-down.svg" /></div>
      <div class="input-fields-container">
        <input type="text"
              placeholder="Move"
               [(ngModel)]="fromLotReorder" />
        <span>to</span>
        <input type="text"
               [(ngModel)]="toLotReorder" />
        <button (click)="reorderLots(fromLotReorder, toLotReorder)">Reorder</button>
      </div>
    </div>
  </div>
  <room-table #roomTable
              [saleRoomData]="saleRoomData$ | async"
              [lots]="lots$ | async"
              [brokersSummary]="brokerSummary"
              [brokersGroupedByWoolType]="brokersGroupedByWoolType"
              [brokers]="brokers$ | async"
              [buyers]="buyers$ | async"
              (transactionEntered)="onTransactionsAdded($event)"
              (toggleView)="switchView($event)"
              (brokerReorder)="reorderBrokers($event)"
              (enabledChanged)="enabledChanged($event)"
              [class.lots]="tableView === 'lots'"></room-table>
</div>

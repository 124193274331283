import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { of } from 'rxjs'
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
  tap
} from 'rxjs/operators'

import { AppState } from '../store.interface'

import {
  SaleDataActionTypes,
  SaleDataCancelled,
  SaleDataRequested,
  SaleDataEditRequested,
  SaleDataLoaded
} from '../actions/sale-data.actions'

import {
  ShowError,
  ShowLoading,
  Dismiss
} from '../actions/data-fetch.actions'

import { Error } from '@shared/models/error.model'
import { selectSaleLoaded, selectAuthState, selectSearchLocationId, selectSearchDate } from '../selectors'
import { SaleDataService } from '@shared/services/sale-data.service'

@Injectable()
export class SaleDataEffects {

  loadSaleData$ = createEffect ( () =>  this.actions$.pipe(
    ofType<SaleDataRequested>(SaleDataActionTypes.SaleDataRequested),
    withLatestFrom(
      this.store.pipe(select(selectSearchLocationId)),
      this.store.pipe(select(selectSearchDate))
    ),
    switchMap(([action, locationId, date]) => {
      this.showLoader('Fetching sales...')
      return this.saleDataService
        .getSales(locationId, date)
        .pipe(
          catchError(err => {
            const error = <Error>{
              hasError: true,
              message: `Error requesting sale data from API ${err.message}`,
            }

            if (err.status !== 0) {
              this.showError(error)
            }

            this.store.dispatch(new SaleDataCancelled({ error })) // MustBeSaleRoomSummaryCancelled
            return of([])
          }),
        )
    }),
    withLatestFrom(this.store.pipe(select(selectAuthState))),
    filter((result) => {
      const [ sales, authState ] = result
      if (!authState.loggedIn) {
        return false
      }

      const saleFound = sales && sales.length >= 1
      if (!saleFound) {
        const error = <Error>{
          hasError: true,
          message: `No sale data could be found for the current date and location set.`,
        }
        this.showError(error)
        this.store.dispatch(new SaleDataCancelled({ error }))
      }
      return saleFound
    }),
    map(result => {
      this.dismissLoader()
      return new SaleDataLoaded(result[0])
    })
  )
  )

  editSaleData$ = createEffect ( () => this.actions$.pipe(
    ofType<SaleDataEditRequested>(SaleDataActionTypes.SaleDataEditRequested),
    withLatestFrom(
      this.store.pipe(select(selectSaleLoaded))
    ),
    tap(() => {
      this.showLoader('Editing sale start time')
    }),
    switchMap(([action]) => {
      return this.saleDataService
        .editSale(action.payload.saleId, action.payload.startTime, action.payload.roomNumber)
        .pipe(
          catchError(err => {
            const error = <Error>{
              hasError: true,
              message: `Error changing sale start time: ${err.message}`,
            }

            if (err.status !== 0) {
              this.showError(error)
            }

            this.store.dispatch(new SaleDataCancelled({ error })) // MustBeSaleRoomSummaryCancelled
            return of([])
          })
        )
    }),
    withLatestFrom(this.store.pipe(select(selectAuthState))),
    filter(result => result[1].loggedIn),
    map(() => {
      return new SaleDataRequested()
    })
  )
  )
  showLoader(message: string) {
    this.store.dispatch(new ShowLoading(message))
  }

  showError(error) {
    this.store.dispatch(new ShowError(error))
  }

  dismissLoader() {
    this.store.dispatch(new Dismiss())
  }

  constructor(
    private actions$: Actions,
    private saleDataService: SaleDataService,
    private store: Store<AppState>,
  ) {}
}

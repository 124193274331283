import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@environments/environment'
import { OrganisationType } from '@shared/enums/organisation-type.enum'
import { ReorderBrokersPayload } from '@shared/models/brokers-reorder.model'
import { CreateTransaction } from '@shared/models/create-transaction.model'
import { Lot } from '@shared/models/lot.model'
import { MoveLotsPayload } from '@shared/models/lots-move.model'
import { ReorderLotsPayload } from '@shared/models/lots-reorder.model'
import { Organisation } from '@shared/models/organisation.model'
import { Transaction } from '@shared/models/transaction.model'
import { Observable } from 'rxjs'

import { Sale } from '../models/sale.model'
import { BaseApiService } from './base-api.service'
import { CreateSale } from './request-models/create-sale.model'
import * as moment from 'moment'


@Injectable({
  providedIn: 'root',
})
export class SaleDataService extends BaseApiService {
  constructor(private http: HttpClient) {
    super()
  }

  createSale(payload: CreateSale): Observable<any> {
    return this.http
      .post(
        `${environment.apiUrl}/sales`,
        payload,
        this.httpOptions
      )
  }

  deleteSale(id: number): Observable<any> {
    return this.http
      .delete(
        `${environment.apiUrl}/sales/${id}`,
        this.httpOptions
      )
  }

  getCreationStatus(id: number): Observable<any> {
    return this.http
      .get(
        `${environment.apiUrl}/sales/${id}/creationStatus`,
        this.httpOptions
      )
  }

  getSales(locationId: string = null, date: string = null): Observable<Sale[]> {
    let params = new HttpParams()
    if (locationId) {
      params = params.set('locationId', locationId)
    }

    if (date) {
      const saleDateString = moment(date).format('DD-MM-YYYY')
      params = params.set('date', saleDateString)
    }

    return this.http
      .get<Sale[]>(`${environment.apiUrl}/sales`, { params, ...this.httpOptions })
  }

  getSaleById(saleId: string): Observable<Sale> {
    return this.http
      .get<Sale>(`${environment.apiUrl}/sales/${saleId}`, this.httpOptions)
  }

  editSale(saleId: string, startTime: string, roomNumber: number): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/sales/${saleId}/setStartTime`,
      { startTime, roomNumber},
      this.httpOptions)
  }

  getBrokers(): Observable<Organisation[]> {
    let params = new HttpParams()
    params = params.set('type', String(OrganisationType.Broker))

    return this.http
      .get<Organisation[]>(`${environment.apiUrl}/organisations`, { params, ...this.httpOptions })
  }

  getBuyers(): Observable<Organisation[]> {
    let params = new HttpParams()
    params = params.set('type', String(OrganisationType.Buyer))

    return this.http
      .get<Organisation[]>(`${environment.apiUrl}/organisations`, { params, ...this.httpOptions })
  }

  getBrokerAndBuyers(): Observable<Organisation[]> {
    return this.http
      .get<Organisation[]>(`${environment.apiUrl}/organisations`, { ...this.httpOptions })
  }

  updateBrokerAndBuyers(id: string,
    code: string,
    name: string,
    type: string,
    foregroundColor: string,
    backgroundColor: string,
    automaticTransferTo: string,
    group: string,
    oldid: string): Observable<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/sales/createBuyer`,
      { id, code, name, type, foregroundColor, backgroundColor, automaticTransferTo, group, oldid},
      this.httpOptions)
  }

  getLots(id: string, roomNumber: number): Observable<Lot[]> {
    return this.http
      .get<Lot[]>(`${environment.apiUrl}/sales/${id}/room/${roomNumber}/lots`,
      this.httpOptions)
  }

  moveLots(payload: MoveLotsPayload): Observable<any> { // ids: string[]
    const { saleId, roomNumber, lots  } = payload
    return this.http
      .post<any>(
        `${environment.apiUrl}/sales/${saleId}/room/${roomNumber}/move-lots`,
        { lots },
        this.httpOptions
      )
  }

  reorderLots(payload: ReorderLotsPayload): Observable<any> {
    const { saleId, roomNumber, id, position } = payload

    const body = {
      id,
      position
    }

    return this.http
      .post<any>(
        `${environment.apiUrl}/sales/${saleId}/room/${roomNumber}/reorder-lot`,
        body,
        this.httpOptions
      )
  }

  reorderBrokers(payload: ReorderBrokersPayload): Observable<any>  {
    const { saleId, roomNumber, brokerId, woolTypeGroup, position } = payload

    const body = {
      brokerId,
      woolTypeGroup,
      position
    }

    return this.http
      .post<any>(
        `${environment.apiUrl}/sales/${saleId}/room/${roomNumber}/reorder-broker`,
        body,
        this.httpOptions
      )
  }

  enableBroker(saleId: string, brokerId: string): Observable<any> {
    const body = {
      brokerId
    }

    return this.http
      .post<any>(
        `${environment.apiUrl}/sales/${saleId}/enable-broker`,
        body,
        this.httpOptions
      )
  }

  disableBroker(saleId: string, brokerId: string): Observable<any> {
    const body = {
      brokerId
    }

    return this.http
      .post<any>(
        `${environment.apiUrl}/sales/${saleId}/disable-broker`,
        body,
        this.httpOptions
      )
  }

  getTransactions(saleId: string): Observable<Transaction[]> {
    const params = new HttpParams().set('saleId', String(saleId))
    return this.http
      .get<Transaction[]>(`${environment.apiUrl}/transactions`, { params })
  }

  addTransaction(saleId: string, roomNumber: number, transaction: CreateTransaction): Observable<CreateTransaction> {
    return this.http
      .post<CreateTransaction>(
        `${environment.apiUrl}/sales/${saleId}/room/${roomNumber}/transactions`,
        transaction,
        this.httpOptions,
      )
  }
}

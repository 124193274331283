import { Component, OnInit } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { LoadedSale } from '@shared/models/sale-loaded.model'
import { OrganisationsRequested } from '@store/actions/organisations.actions'
import { SaleDataRequested } from '@store/actions/sale-data.actions'
import { selectSearchVisible } from '@store/selectors'
import { AppState } from '@store/store.interface'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-sales-manager',
  templateUrl: './sales-manager.component.html',
  styleUrls: ['./sales-manager.component.scss'],
})
export class SalesManagerComponent implements OnInit {
  sales: Array<LoadedSale> = []
  searchVisible$: Observable<boolean>

  constructor(private store: Store<AppState>) {
    this.store.dispatch(new SaleDataRequested())
    this.store.dispatch(new OrganisationsRequested())
  }

  ngOnInit() {
    this.searchVisible$ = this.store.pipe(select(selectSearchVisible))
    this.store.select('sales').subscribe(sales => {
      this.sales = sales
    })
  }

  onSearchEntered() {
    this.store.dispatch(new SaleDataRequested())
  }
}

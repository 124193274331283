import { Action } from '@ngrx/store'
import { Organisation } from '@shared/models/organisation.model'

export enum OrganisationActionTypes {
  OrganisationsRequested = '[ORGANISATIONS] Organisations Requested',
  OrganisationsLoaded = '[ORGANISATIONS] Organisations Loaded',
  OrganisationsCleared = '[ORGANISATIONS] Organisations Cleared',
  OrganisationsEdit = '[ORGANISATIONS] Organisations Edit',
  OrganisationsCancel = '[ORGANISATIONS] Organisations Cancel',
}

export class OrganisationsEdit implements Action {
  readonly type = OrganisationActionTypes.OrganisationsEdit
}

export class OrganisationsCancel implements Action {
  readonly type = OrganisationActionTypes.OrganisationsCancel
}


export class OrganisationsRequested implements Action {
  readonly type = OrganisationActionTypes.OrganisationsRequested
}

export class OrganisationsLoaded implements Action {
  readonly type = OrganisationActionTypes.OrganisationsLoaded

  constructor (public payload: {
    brokers: Organisation[],
    buyers: Organisation[],
    brokersAndBuyers: Organisation[],
  }) {}
}

export class OrganisationsCleared implements Action {
  readonly type = OrganisationActionTypes.OrganisationsCleared
}

export type OrganisationsActions = OrganisationsRequested | OrganisationsLoaded |
 OrganisationsCleared | OrganisationsEdit | OrganisationsCancel

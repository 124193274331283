import { AuthActionTypes, Login, Logout } from '../actions/auth.actions'

export interface AuthState {
  loggedIn: boolean
  token: string
}

export const initialState: AuthState = {
  loggedIn: false,
  token: undefined,
}

export function authReducer(state = initialState, action: Login | Logout): AuthState {

  if (!action || !action.type) {
    return initialState
  }

  switch (action.type) {
    case AuthActionTypes.LoginAction:
      return {
        loggedIn: true,
        token: action.payload.token,
      }
    case AuthActionTypes.LogoutAction:
      return {
        loggedIn: false,
        token: undefined,
      }
    default:
      return state
  }
}

import { Component, OnInit } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { AppState } from '@store/store.interface'
import { Sale } from '@shared/models/sale.model'
import { Actions } from '@store/actions'
import { sales } from '@mocks/data/sales'
import { Observable } from 'rxjs'
import { selectSearchVisible } from '@store/selectors'
import { ToggleVisible } from '@store/actions/search.actions'
import { Router } from '@angular/router'
import { OrganisationsEdit } from '@store/actions/organisations.actions'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  date: string = new Date().toISOString()

  dummySale: Sale = sales[0]
  searchVisible$: Observable<boolean>

  constructor(private store: Store<AppState>, public router: Router) {}

  ngOnInit() {
    this.searchVisible$ = this.store.pipe(select(selectSearchVisible))
  }

  addSale() {
    this.store.dispatch(new Actions.SaleFormActions.SaleFormAddSale())
  }

  editOrg() {
    this.store.dispatch(new OrganisationsEdit())
  }

  toggleSearch() {
    this.store.dispatch(new ToggleVisible())
  }

  logout() {
    this.store.dispatch(new Actions.AuthActions.Logout())
  }
}

<div class="expandable-row-container"
     [ngClass]="{ 'disabled': enabled === false }">
  <div class="row-header"
       [ngClass]="true ? brokerId : ''">
    <div>
    <input [(ngModel)]="enabled"
type="checkbox" (change)="onChangeEnabled()" />
    </div>
    <div>
      <ng-content select=".drag-icon"></ng-content>
      <input #position
             type="number"
             min="1"
             max="{{ maxPosition }}"
             value="{{order + 1}}"
             (keyup.enter)="onBrokerReordered(position.value)">
    </div>
    <div>
      <img *ngIf="imageShowing"
           [src]="brokerImage"
           (loaded)="showImage()"
           (error)="hideImage()" />
      <h3 *ngIf="!imageShowing">{{brokerId}}</h3>
    </div>
    <div>{{ lotCount }}</div>
    <div>{{ baleCount }}</div>
    <div>{{ woolTypeGroup }}</div>
  </div>
</div>

<div 
  class="custom-select-box"
  [ngClass]="{ 'open': isOpen }"
  #select
>
  <div class="content">
    <div class="label"><span>{{ currentValue }}</span></div>
    <img #dropdown (click)="toggleOpen()" class="arrow" src="assets/img/icons/arrow-down.svg"/>
  </div>
  
  <div *ngIf="isOpen" class="options">
    <div *ngFor="let option of options" (click)="selectOption(option)">
      
      <span> <b *ngIf="option.label == 20; else elseBlock">{{ option.label }}</b>
        <ng-template #elseBlock>{{ option.label }}</ng-template>
        </span>
    </div>
  </div>
  
  
</div>

import { Routes } from '@angular/router'
import { SalesManagerComponent } from '../pages/sales-manager/sales-manager.component'
import { SaleRoomComponent } from '../pages/sale-room/sale-room.component'
import { LoginComponent } from '../pages/login/login.component'
import { SaleRoomGuard } from '@store/guards/sale-room.guard'
import { AuthGuard } from '@store/guards/auth.guard'

export const PageRoutes: Routes = [
  {
    path: '',
    component: SalesManagerComponent,
    canActivate: [ AuthGuard ]
  },
  {
    path: 'sale-room',
    component: SaleRoomComponent,
    canActivate: [ SaleRoomGuard, AuthGuard ]
  },
  {
    path: 'login',
    component: LoginComponent
  }
]

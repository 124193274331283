import { Action } from '@ngrx/store'
import { Error } from '@shared/models/data-fetch.model'

export enum DataFetchActionsTypes {
  showLoading = '[DATA FETCH] Fetching Data',
  showError = '[DATA FETCH] Error',
  dismiss = '[DATA FETCH] Dismiss Modal',
  reset = '[DATA FETCH] Reset'
}

export class ShowLoading implements Action {
  readonly type = DataFetchActionsTypes.showLoading

  constructor (public message: string = 'Loading...') {

  }
}

export class ShowError implements Action {
  readonly type = DataFetchActionsTypes.showError
  constructor (public error: Error) {}
}

export class Dismiss implements Action {
  readonly type = DataFetchActionsTypes.dismiss
}

export class DataFetchReset implements Action {
  readonly type = DataFetchActionsTypes.reset
}

export type DataFetchActions =
  | ShowLoading
  | ShowError
  | Dismiss
  | DataFetchReset

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'app-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss']
})
export class SlideToggleComponent implements OnInit {
  @Input() aValue = 'Off'
  @Input() bValue = 'On'
  @Input() selected = 1
  @Output() toggle: EventEmitter<number> = new EventEmitter()

  constructor() { }

  ngOnInit() {
  }

  onToggle () {
    this.selected = 1 - this.selected
    this.toggle.emit(this.selected)
  }
}

import { Action } from '@ngrx/store'
import { Lot } from '@shared/models/lot.model'
import { SaleRoomData } from '@shared/models/sale-room-data.model'
import { SaleRoomDataError } from '@store/reducers/sale-room-data.reducer'
import { CreateSale } from '@shared/services/request-models/create-sale.model'

export enum SaleRoomDataActionTypes {
  SaleRoomDataRequested = '[SALES LIST] Sale Room Data Requested',
  SaleRoomDataLoaded = '[SALE API] Sale RoomData Loaded',
  SaleRoomDataCleared = '[LOGOUT SIDE FX] Sale Room Data Cleared',
  SaleRoomDataCancelled = '[SALE API] Sale Room Data Cancelled',
  SaleRoomDataLotsRequested = '[SALE API] Sale Room Lots Requested',
  SaleRoomDataLotsLoaded = '[SALE API] Sale Room Lots Loaded',
  SaleRoomLotReorderRequested = '[SALE API] Sale Room Lot Reorder Requested',
  SaleRoomLotReordered = '[SALE API] Sale Room Lot Reordered',
  SaleRoomLotReorderCancelled = '[SALE API] SaleRoom Lot Reorder Cancelled',
  SaleRoomLotsMoveRequested = '[SALE API] Sale Room Lots Move Requested',
  SaleRoomLotsMoved = '[SALE API] Sale Room Lots Moved',
  SaleRoomLotsMoveCancelled = '[SALE API] SaleRoom Lots Move Cancelled',
  SaleRoomBrokerReorderRequested = '[SALE API] Sale Room Broker Reorder Requested',
  SaleRoomBrokerReordered = '[SALE API] Sale Room Broker Reordered',
  SaleRoomBrokerReorderCancelled = '[SALE API] SaleRoom Broker Reorder Cancelled',
  SaleRoomBrokerDisableRequested = '[SALE API] Sale Room Broker Disable Requested',
  SaleRoomBrokerDisabled = '[SALE API] Sale Room Broker Disabled',
  SaleRoomBrokerDisableCancelled = '[SALE API] Sale Room Broker Disable Cancelled',
  SaleRoomBrokerEnableRequested = '[SALE API] Sale Room Broker Enable Requested',
  SaleRoomBrokerEnabled = '[SALE API] Sale Room Broker Enabled',
  SaleRoomBrokerEnableCancelled = '[SALE API] Sale Room Broker Enable Cancelled',
  SaleRoomDataDeleted =  '[SALE API] Sale Room Deleted',
}

export class SaleRoomDataRequested implements Action {
  readonly type = SaleRoomDataActionTypes.SaleRoomDataRequested
  constructor(
    public payload: {
      id: string
      locationId: string
      saleNo: number
      season: number
      roomNumber: number
      startTime: string
      roomCount: number
    },
  ) {}
}

export class SaleRoomDataLoaded implements Action {
  readonly type = SaleRoomDataActionTypes.SaleRoomDataLoaded
  constructor(public payload: { SaleRoomData: SaleRoomData }) {}
}

export class SaleRoomDataCleared implements Action {
  readonly type = SaleRoomDataActionTypes.SaleRoomDataCleared
}

export class SaleRoomDataCancelled implements Action {
  readonly type = SaleRoomDataActionTypes.SaleRoomDataCancelled
  constructor(public payload: { error: SaleRoomDataError }) {}
}

export class SaleRoomDataLotsRequested implements Action {
  readonly type = SaleRoomDataActionTypes.SaleRoomDataLotsRequested
  constructor(public payload: { saleId: string; roomNumber: number }) {}
}

export class SaleRoomDataLotsLoaded implements Action {
  readonly type = SaleRoomDataActionTypes.SaleRoomDataLotsLoaded
  constructor(public payload: { lots: Lot[] }) {}
}

export class SaleRoomLotReorderRequested implements Action {
  readonly type = SaleRoomDataActionTypes.SaleRoomLotReorderRequested
  constructor(
    public payload: {
      saleId: string
      roomNumber: number
      position: number
      id: number
    },
  ) {}
}

export class SaleRoomLotReordered implements Action {
  readonly type = SaleRoomDataActionTypes.SaleRoomLotReordered
  constructor(public payload: { lots: Lot[] }) {}
}

export class SaleRoomLotReorderCancelled implements Action {
  readonly type = SaleRoomDataActionTypes.SaleRoomLotReorderCancelled
  constructor(public payload: { error: SaleRoomDataError }) {}
}

export class SaleRoomLotsMoveRequested implements Action {
  readonly type = SaleRoomDataActionTypes.SaleRoomLotsMoveRequested
  constructor(
    public payload: { saleId: string; roomNumber: number; lots: number[] },
  ) {}
}

export class SaleRoomLotsMoved implements Action {
  readonly type = SaleRoomDataActionTypes.SaleRoomLotsMoved
  constructor(public payload: { ids: number[] }) {}
}

export class SaleRoomLotsMoveCancelled implements Action {
  readonly type = SaleRoomDataActionTypes.SaleRoomLotsMoveCancelled
  constructor(public payload: { error: SaleRoomDataError }) {}
}

export class SaleRoomBrokerDisableRequested implements Action {
  readonly type = SaleRoomDataActionTypes.SaleRoomBrokerDisableRequested
  constructor(
    public payload: {
      brokerId: string
      roomNumber: number
      saleId: string
    },
  ) {}
}

export class SaleRoomBrokerDisabled implements Action {
  readonly type = SaleRoomDataActionTypes.SaleRoomBrokerDisabled
  constructor(public payload: { lots: Lot[] }) {}
}

export class SaleRoomBrokerDisableCancelled implements Action {
  readonly type = SaleRoomDataActionTypes.SaleRoomBrokerDisableCancelled
  constructor(public payload: { error: SaleRoomDataError }) {}
}
export class SaleRoomBrokerEnableRequested implements Action {
  readonly type = SaleRoomDataActionTypes.SaleRoomBrokerEnableRequested
  constructor(
    public payload: {
      brokerId: string
      roomNumber: number
      saleId: string
    },
  ) {}
}

export class SaleRoomBrokerEnabled implements Action {
  readonly type = SaleRoomDataActionTypes.SaleRoomBrokerEnabled
  constructor(public payload: { lots: Lot[] }) {}
}

export class SaleRoomBrokerEnableCancelled implements Action {
  readonly type = SaleRoomDataActionTypes.SaleRoomBrokerEnableCancelled
  constructor(public payload: { error: SaleRoomDataError }) {}
}

export class SaleRoomBrokerReorderRequested implements Action {
  readonly type = SaleRoomDataActionTypes.SaleRoomBrokerReorderRequested
  constructor(
    public payload: {
      order: string[]
      brokerId: string
      woolTypeGroup: string
      saleId: string
      position: number
      roomNumber: number
      lots?: Lot[]
    },
  ) {}
}

export class SaleRoomBrokerReordered implements Action {
  readonly type = SaleRoomDataActionTypes.SaleRoomBrokerReordered
  constructor(public payload: { lots: Lot[] }) {}
}

export class SaleRoomBrokerReorderCancelled implements Action {
  readonly type = SaleRoomDataActionTypes.SaleRoomBrokerReorderCancelled
  constructor(public payload: { error: SaleRoomDataError }) {}
}

export class SaleRoomDataDeleted implements Action {
  readonly type = SaleRoomDataActionTypes.SaleRoomDataDeleted
  constructor(
    public payload: {
      id: string,
      sale: CreateSale,
    },
  ) {}
}

export type SaleRoomActions =
  | SaleRoomDataRequested
  | SaleRoomDataLoaded
  | SaleRoomLotsMoveRequested
  | SaleRoomLotsMoved
  | SaleRoomDataCancelled
  | SaleRoomDataCleared
  | SaleRoomLotsMoveCancelled
  | SaleRoomBrokerReorderRequested
  | SaleRoomBrokerReordered
  | SaleRoomBrokerReorderCancelled
  | SaleRoomLotReorderRequested
  | SaleRoomLotReordered
  | SaleRoomLotReorderCancelled
  | SaleRoomBrokerDisableRequested
  | SaleRoomBrokerDisabled
  | SaleRoomBrokerDisableCancelled
  | SaleRoomBrokerEnableRequested
  | SaleRoomBrokerEnabled
  | SaleRoomBrokerEnableCancelled
  | SaleRoomDataDeleted

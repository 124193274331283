import { Location } from '@shared/models/location.model'

 enum Locations {
  Melbourne = 'M',
  Fremantle = 'F',
  Sydney = 'S',
  Centenary = 'C',
}

const locationsData = {}
locationsData[Locations.Melbourne] = <Location>{
  id: Locations.Melbourne,
  name: 'Melbourne',
  rooms: 2,
  timezone: 'Australia/Melbourne',
}
locationsData[Locations.Fremantle] = <Location>{
  id: Locations.Fremantle,
  name: 'Fremantle',
  rooms: 1,
  timezone: 'Australia/Perth',
}
locationsData[Locations.Sydney] = <Location>{
  id: Locations.Sydney,
  name: 'Sydney',
  rooms: 2,
  timezone: 'Australia/Sydney',
}
locationsData[Locations.Centenary] = <Location>{
  id: Locations.Centenary,
  name: 'Centenary',
  rooms: 1,
  timezone: 'Australia/Melbourne',
}

export { locationsData, Locations }

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { environment } from '@environments/environment'
import { BrokerEnabledChanged } from '@shared/models/broker-enabled-changed.model'

@Component({
  selector: 'broker-row',
  templateUrl: './broker-row.component.html',
  styleUrls: ['./broker-row.component.scss'],
})
export class BrokerRowComponent implements OnInit {
  rows = [1, 2, 3, 4, 5, 6]
  @Input() order: number
  @Input() maxPosition: number
  @Input() brokerId: string
  @Input() brokerCode: string
  @Input() pieceCount: number
  @Input() lotCount: number
  @Input() baleCount: number
  @Input() woolTypeGroup: string
  @Input() enabled: boolean
  @Output() reOrderBroker: EventEmitter<number> = new EventEmitter()
  @Output() enabledChanged: EventEmitter<
    BrokerEnabledChanged
  > = new EventEmitter()
  color: string
  imageShowing = false

  constructor() {}

  ngOnInit() {
    if (this.brokerId) {
      const colors = ['blue', 'green', 'light-blue', 'red']

      const colorMap = {
        elders: 'red',
        esm: 'red',
        michell: 'blue',
        aw: 'light-blue',
        sbb: 'green',
        ackroyd: 'beige',
      }

      this.color =
        colorMap[this.brokerId.toLowerCase()] ||
        colors[Math.floor(Math.random() * colors.length)]
    }
  }

  onBrokerReordered(newPosition: number): void {
    if (+newPosition !== this.order + 1) {
      this.reOrderBroker.emit(+newPosition)
    }
  }

  get brokerImage() {
    return `${
      environment.s3Url
    }/broker-images/${this.brokerCode.toLowerCase()}.png`
  }

  showImage() {
    this.imageShowing = true
  }

  hideImage() {
    this.imageShowing = false
  }

  onChangeEnabled() {
    this.enabledChanged.emit({
      brokerId: this.brokerId,
      enabled: this.enabled,
    })
  }
}

import { Action } from '@ngrx/store'

export enum SearchActionTypes {
  SetSearchLocationAction = '[SetSearchLocation] Action',
  SetSearchDateAction = '[SetDate] Action',
  ClearSearchAction = '[ClearSearch] Action',
  ToggleVisibleAction = '[ToggleVisible] Action',
}

export class SetSearchLocation implements Action {
  readonly type = SearchActionTypes.SetSearchLocationAction
  constructor(public payload: { locationId: string }) { }
}

export class SetSearchDate implements Action {
  readonly type = SearchActionTypes.SetSearchDateAction
  constructor(public payload: { date: string }) { }
}

export class ClearSearch implements Action {
  readonly type = SearchActionTypes.ClearSearchAction
}

export class ToggleVisible implements Action {
  readonly type = SearchActionTypes.ToggleVisibleAction
}

export type SearchActions = SetSearchLocation | SetSearchDate | ClearSearch | ToggleVisible

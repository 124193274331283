import { Action, ActionReducer, ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store'
import { localStorageSync } from 'ngrx-store-localstorage'
import { AuthState, authReducer } from './reducers/auth.reducer'
import { Organisations } from '@shared/models/organisations.model'
import { organisationsReducer } from './reducers/organisations.reducer'
import { searchReducer } from './reducers/search.reducer'
import { SearchState } from './reducers/search.reducer'

const FEATURE_NAME = 'woolex-sale-roster-cache'
const STORE_KEYS_TO_PERSIST = [
  'auth',
  'organisations',
  'selectedSaleRoom',
  'search'
]

export interface CachedState {
  auth: AuthState,
  organisations: Organisations,
  search: SearchState
}

export const reducers: ActionReducerMap<CachedState> = {
  auth: authReducer,
  organisations: organisationsReducer,
  search: searchReducer
}

export const getCachedState = createFeatureSelector<CachedState>(FEATURE_NAME)

export function localStorageSyncReducer(reducer: ActionReducer<CachedState>): ActionReducer<CachedState> {
  return localStorageSync({
    keys: STORE_KEYS_TO_PERSIST,
    rehydrate: true,
  })(reducer)
}

export const metaReducers: Array<MetaReducer<CachedState, Action>> = [localStorageSyncReducer]

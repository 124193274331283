import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { SaleDataService } from '@shared/services/sale-data.service'
import { of } from 'rxjs'
import { catchError, delay, map, switchMap, withLatestFrom } from 'rxjs/operators'

import { SaleDataRequested } from '../actions/sale-data.actions'
import {
  SaleFormActionTypes,
  SaleFormCreateSale,
  SaleFormEmitSaleError,
  SaleFormPollStatus,
  SaleFormVerifySale,
} from '../actions/sale-form.actions'
import { selectSaleLoaded } from '../selectors'
import { AppState } from '../store.interface'

@Injectable()
export class SaleFormEffects {

  createSale$ = createEffect ( () => this.actions$.pipe(
    ofType<SaleFormCreateSale>(SaleFormActionTypes.SaleFormCreateSale),
    withLatestFrom(this.store.pipe(select(selectSaleLoaded))),
    switchMap(([action]) => {
      return this.saleDataService
        .createSale(action.payload)
        .pipe(
          map((creationStatus) => {
            return new SaleFormPollStatus(creationStatus.id)
          }),
          catchError(err => {
            if (err.status === 0) {
              return of(null)
            }

            return of(new SaleFormEmitSaleError(`Could not create the sale: ${err.message}.`))
          })
        )
    })
  )
  )

  pollStatus$ = createEffect ( () => this.actions$.pipe(
    ofType<SaleFormPollStatus>(SaleFormActionTypes.SaleFormPollStatus),
    withLatestFrom(this.store.pipe(select(selectSaleLoaded))),
    delay(3000),
    switchMap(([action]) => {
      return this.saleDataService
        .getCreationStatus(action.id)
        .pipe(
          map((creationStatus) => {
            if (creationStatus.status === 'pending') {
              return new SaleFormPollStatus(action.id)
            } else if (creationStatus.status === 'succeeded') {
              this.store.dispatch(new SaleFormVerifySale())
              return new SaleDataRequested()
            }

            return new SaleFormEmitSaleError(`Could not create the sale. The error returned was: ${creationStatus.errorMessage}`)
          }),
          catchError(err => {
            const error = `Could not check the status of sale creation: ${err.message}.` +
              `Check back in a while to see if the sale has been created.`
            return of(new SaleFormEmitSaleError(error))
          })
        )
    })
  )
  )
  constructor(
    private actions$: Actions,
    private saleDataService: SaleDataService,
    private store: Store<AppState>,
  ) {}
}

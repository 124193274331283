import { Action } from '@ngrx/store'
import { Sale } from '@shared/models/sale.model'
import { Error } from '@shared/models/error.model'

export enum SaleDataActionTypes {
  SaleDataRequested = '[SALE FORM] SaleData Requested',
  SaleDataEditRequested = '[SALE FORM] SaleData Edit Requested',
  SaleDataLoaded = '[SALE SUMMARY API] SaleData Loaded',
  SaleDataCleared = '[LOGOUT SIDE FX] SaleData Cleared',
  SaleDataCancelled = '[SALE SUMMARY API] SaleData Cancelled',
}

export class SaleDataRequested implements Action {
  readonly type = SaleDataActionTypes.SaleDataRequested
}

export class SaleDataEditRequested implements Action {
  readonly type = SaleDataActionTypes.SaleDataEditRequested
  constructor(public payload: { saleId: string, startTime: string, roomNumber: number}) {}
}

export class SaleDataLoaded implements Action {
  readonly type = SaleDataActionTypes.SaleDataLoaded
  constructor(public payload: Sale[]) {}
}

export class SaleDataCleared implements Action {
  readonly type = SaleDataActionTypes.SaleDataCleared
  constructor() {}
}

export class SaleDataCancelled implements Action {
  readonly type = SaleDataActionTypes.SaleDataCancelled
  constructor(public payload: { error: Error }) {}
}

export type SaleDataActions =
  | SaleDataRequested
  | SaleDataEditRequested
  | SaleDataLoaded
  | SaleDataCancelled
  | SaleDataCleared

<div class="sale-container">
<h2>{{ sale.startTime | date : 'EEEE d MMMM y' : 'UTC' }} 
  <button (click)="deleteSale()" *ngIf="sale.canDelete"> 
    <div class="delete">Delete</div>
  </button>
</h2>  
  <p>{{ sale.locationId }}{{ sale.saleNo }} - {{ sale.locationName }}</p>
  <app-room-summary
    *ngFor="let saleRoomSummary of sale.rooms; let roomIndex = index"
    [saleRoomSummary]="saleRoomSummary"
    [roomNumber]="roomIndex + 1"
    [date]="roomIndex == 0 ? sale.startTime:sale.startTime2"
    [saleId]="sale.id"
    (viewRoom)="viewRoom(roomIndex)"
  ></app-room-summary>

  <app-modal [@modal] *ngIf="showConfirm">
    <div class="loading-modal modal-content add-sale-container">
      <div class="error">
        <div  class="content">
          <h3> Warning </h3>
          <p>Sale {{sale.locationId}}{{sale.saleNo}} for {{startTime.toDateString()}}  has been selected for deletion. Continue?</p>
          <button class="try-again" (click)="deleteThis()">Yes</button>
          <button class="create-sale" (click)="cancelThis()">No</button>
          
        </div>
    </div>
    </div>
    
    <div [@zoomAnimation] class="close-modal" (click)="cancelThis()">
      <img src="assets/img/icons/close.svg"/>
    </div>
  </app-modal>
</div>

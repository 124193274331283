import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { BrokerEnabledChanged } from '@shared/models/broker-enabled-changed.model'
import { BrokerGroupedByWooltype } from '@shared/models/broker-grouped-by-wooltype.model'
import { BrokerSummary } from '@shared/models/broker-summary.model'
import { ReorderBrokersPayload } from '@shared/models/brokers-reorder.model'
import { CreateTransaction } from '@shared/models/create-transaction.model'
import { Lot } from '@shared/models/lot.model'
import { Organisation } from '@shared/models/organisation.model'
import { SaleRoomData } from '@shared/models/sale-room-data.model'
import { SaleRoomSummary } from '@shared/models/sale-room-summary.model'
import { Transaction } from '@shared/models/transaction.model'
import { Actions } from '@store/actions'
import { Dismiss, ShowError, ShowLoading } from '@store/actions/data-fetch.actions'
import { SaleRoomBrokerDisableRequested, SaleRoomBrokerEnableRequested } from '@store/actions/sale-room-data.actions'
import { AddTransaction } from '@store/actions/transaction.actions'
import {
  selectBaleCount,
  selectBrokers,
  selectBuyers,
  selectLotCount,
  selectRoomNumber,
  selectSaleBrokersGroupedByWoolType,
  selectSaleBrokerSummary,
  selectSaleId,
  selectSaleLots,
  selectStartTime,
} from '@store/selectors'
import { AppState } from '@store/store.interface'
import { combineLatest, Observable, Subscription } from 'rxjs'
import { RoomTableComponent } from 'src/app/components/common/room-table/room-table.component'

@Component({
  selector: 'app-sale-room',
  templateUrl: './sale-room.component.html',
  styleUrls: ['./sale-room.component.scss'],
})
export class SaleRoomComponent implements OnInit, OnDestroy {
  @ViewChild('roomTable') roomTable: RoomTableComponent
  combinationSubscription: Subscription
  saleRoomSummary: SaleRoomSummary
  saleRoomData$: Observable<SaleRoomData>
  room$: Observable<any>
  brokerSummary: BrokerSummary[]
  brokersGroupedByWoolType: BrokerGroupedByWooltype[]
  buyers$: Observable<Organisation[]>
  brokers$: Observable<Organisation[]>
  lots$: Observable<Lot[]>
  lots: Lot[]
  latestTransactions$: Observable<Map<number, Transaction>>
  tableView = 'brokers'
  fromLotSelect: string
  toLotSelect: string
  roomNumber: number
  startTime: string
  saleId: string
  brokerId: string
  lotNumber: string

  constructor(private store: Store<AppState>, private route: ActivatedRoute) {}

  ngOnInit() {
    this.combinationSubscription = combineLatest(
      this.store.pipe(select(selectSaleBrokerSummary)),
      this.store.pipe(select(selectSaleBrokersGroupedByWoolType)),
      this.store.pipe(select(selectLotCount)),
      this.store.pipe(select(selectBaleCount)),
      this.store.pipe(select(selectRoomNumber)),
      this.store.pipe(select(selectStartTime)),
      this.store.pipe(select(selectSaleId)),
    ).subscribe(
      ([
        brokerSummary,
        brokersGroupedByWoolType,
        lotCount,
        baleCount,
        roomNumber,
        startTime,
        saleId,
      ]) => {
        this.brokerSummary = brokerSummary
        this.brokersGroupedByWoolType = brokersGroupedByWoolType
        this.roomNumber = roomNumber
        this.startTime = startTime
        this.saleId = saleId
        this.saleRoomSummary = {
          brokers: brokerSummary.length,
          lots: lotCount,
          bales: baleCount,
        }
      },
    )

    this.saleRoomData$ = this.store.pipe(select('selectedSaleRoom'))
    this.buyers$ = this.store.pipe(select(selectBuyers))
    this.brokers$ = this.store.pipe(select(selectBrokers))
    this.lots$ = this.store.pipe(select(selectSaleLots))

    this.lots$.subscribe(lots => {
      this.lots = lots
    })
  }

  ngOnDestroy() {
    this.combinationSubscription.unsubscribe()
  }

  onTransactionsAdded(transactions) {
    this.pushTransactions(transactions)
  }

  onClearFilter() {
    this.brokerId = null
    this.lotNumber = null
    this.onFilter()
  }

  onFilter() {
    this.roomTable.brokerId = this.brokerId
    this.roomTable.lotNumber = this.lotNumber
    this.roomTable.rows = this.roomTable.createTableRows(this.roomTable.lots)
    this.roomTable.selectRowIndexOffset(0, 0)
  }

  private pushTransactions(transactions: CreateTransaction[]) {
    const payload = transactions.map(transaction => {
      return {
        saleId: this.lots[0].saleId,
        roomNumber: this.roomNumber,
        transaction,
      }
    })

    this.store.dispatch(new AddTransaction(payload))
  }

  showLoader() {
    this.store.dispatch(new ShowLoading())
  }

  showError(error) {
    this.store.dispatch(new ShowError(error))
  }

  dismissLoader() {
    this.store.dispatch(new Dismiss())
  }

  switchView(view) {
    this.tableView = view
  }

  get otherRoom() {
    // Need to double check if room indeed contains 2 rooms
    return this.roomNumber === 1 ? 2 : 1
  }

  reorderLots(fromLotPosition: number, toLotPosition: number) {
    const { lots } = this
    const position = toLotPosition
    const foundLot = lots[fromLotPosition - 1]

    if (!foundLot) {
      return
    }

    const id = foundLot.id

    if (position <= 0 || !id) {
      return
    }

    return this.store.dispatch(
      new Actions.SaleDataActions.SaleRoomLotReorderRequested({
        saleId: lots[0].saleId,
        roomNumber: this.roomNumber,
        id,
        position,
      }),
    )
  }

  moveLots(position1, position2) {
    const { lots } = this
    const ids = []
    const index1 = position1 - 1
    const index2 = position2 - 1

    if (index1 !== -1 && index2 !== -1) {
      const start = index1 >= index2 ? index2 : index1
      const end = start === index1 ? index2 : index1

      for (let i = start; i <= end; i++) {
        ids.push(lots[i].id) // might not be in order
      }
    }

    return this.store.dispatch(
      new Actions.SaleDataActions.SaleRoomLotsMoveRequested({
        saleId: lots[0].saleId,
        roomNumber: this.roomNumber,
        lots: ids,
      }),
    )
  }

  reorderBrokers(payload: ReorderBrokersPayload) {
    const { lots } = this
    const {
      order,
      brokerId,
      woolTypeGroup,
      saleId,
      position,
      roomNumber,
    } = payload

    return this.store.dispatch(
      new Actions.SaleDataActions.SaleRoomBrokerReorderRequested({
        order,
        brokerId,
        woolTypeGroup,
        saleId,
        position,
        roomNumber,
        lots,
      }),
    )
  }

  enabledChanged(enabledChanged: BrokerEnabledChanged) {
    if (enabledChanged.enabled === true) {
      this.store.dispatch(
        new SaleRoomBrokerEnableRequested({
          brokerId: enabledChanged.brokerId,
          saleId: this.saleId,
          roomNumber: this.roomNumber,
        }),
      )
    } else {
      this.store.dispatch(
        new SaleRoomBrokerDisableRequested({
          brokerId: enabledChanged.brokerId,
          saleId: this.saleId,
          roomNumber: this.roomNumber,
        }),
      )
    }
  }
}

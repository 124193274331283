import { DragDropModule } from '@angular/cdk/drag-drop'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { MatIconModule } from '@angular/material/icon'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatNativeDateModule } from '@angular/material/core'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatSelectModule } from '@angular/material/select'
import { MatFormFieldModule } from '@angular/material/form-field'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { PreloadAllModules, RouterModule } from '@angular/router'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { TokenInterceptor } from '@shared/interceptors/token.interceptor'
import { RequestInterceptor } from '@shared/interceptors/request.interceptor'
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker'

import { AppComponent } from './app.component'
import { Components, EntryComponents } from './imports/components'
import { Directives } from './imports/directives'
import { Pipes } from './imports/pipes'
import { PageRoutes } from './imports/routes'
import { Services } from './imports/services'
import {
  AuthEffects,
  OrganisationsEffects,
  SaleDataEffects,
  SaleFormEffects,
  SaleRoomDataEffects,
  TransactionEffects,
} from './store/effects'
import { metaReducers } from './store/meta-reducers'
import { reducers } from './store/reducers'

export const environment = {
  production: false,
}

@NgModule({
    declarations: [...Components, ...Directives, ...Pipes],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(PageRoutes, {
            preloadingStrategy: PreloadAllModules,
        }),
        StoreModule.forRoot(reducers as any, { metaReducers }),
        EffectsModule.forRoot([
            SaleDataEffects,
            SaleRoomDataEffects,
            AuthEffects,
            SaleFormEffects,
            TransactionEffects,
            OrganisationsEffects,
        ]),
        HttpClientModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatInputModule,
        MatIconModule,
        MatSelectModule,
        DragDropModule,
        NgxMaterialTimepickerModule,
        !environment.production ? StoreDevtoolsModule.instrument() : [],
    ],
    providers: [
        ...Services,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

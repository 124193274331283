import * as SaleDataActions from '../actions/sale-data.actions'
import { Sale } from '@shared/models/sale.model'

import {
  SaleDataActionTypes
} from '../actions/sale-data.actions'

export const initialState: Array<Sale> = []

export function saleDataReducer(state: Array<Sale> = initialState, action: SaleDataActions.SaleDataActions ): Array<Sale> {

  if (!action || !action.type) {
    return initialState
  }

  switch (action.type) {
    case SaleDataActionTypes.SaleDataLoaded:
      return action.payload.length > 0 ? action.payload : state

    case SaleDataActionTypes.SaleDataCancelled:
      return initialState

    case SaleDataActionTypes.SaleDataCleared:
      return initialState

    default:
      return state
  }
}

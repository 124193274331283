import { Sale } from '@shared/models/sale.model'

import * as fromAuth from './reducers/auth.reducer'
import * as fromDataFetch from './reducers/data-fetch.reducer'
import * as fromOrganisations from './reducers/organisations.reducer'
import * as fromSaleData from './reducers/sale-data.reducer'
import * as fromSaleForm from './reducers/sale-form.reducer'
import * as fromSaleRoomData from './reducers/sale-room-data.reducer'
import * as fromSearch from './reducers/search.reducer'
import { ActionReducerMap } from '@ngrx/store'

export interface AppState {
  auth: fromAuth.AuthState,
  sales: Array<Sale>,
  selectedSaleRoom: fromSaleRoomData.SaleRoomDataState,
  saleForm: fromSaleForm.SaleFormState,
  organisations: fromOrganisations.OrganisationsState,
  dataFetch: fromDataFetch.DataFetchState,
  search: fromSearch.SearchState,
}

export const reducers: ActionReducerMap<AppState> =  {
  auth: fromAuth.authReducer,
  sales: fromSaleData.saleDataReducer,
  selectedSaleRoom: fromSaleRoomData.saleRoomDataReducer,
  saleForm: fromSaleForm.saleFormReducer,
  organisations: fromOrganisations.organisationsReducer,
  dataFetch: fromDataFetch.dataFetchReducer,
  search: fromSearch.searchReducer,
}

import { Transaction } from '@shared/models/transaction.model'
import { TransactionType } from '@shared/enums/transaction-type.enum'
import { Organisation } from '@shared/models/organisation.model'

const withdrawnExpression = /^(WD){1}$/i
const noBidExpression = /^(NB){1}$/i
const internalTransferExpression = /^([A-z|a-z]{3,5})\s{1}([A-z|a-z]{3,5})/
const passedInExpression = /^(?:[0-9]+[A-Z]+\s{1})(P{1})$/i
const soldExpression = /(?:^[0-9]+)([A-Z|a-z]{3,5})/i
const priceExpression = /^\d+(?!.+(WD|NL|NB))/

function getInputCodeOutcome(inputCode: string): TransactionType | null {
  if (!isValid(inputCode)) {
    return null
  }

  if (isWithdrawn(inputCode)) {
    return TransactionType.WithDrawn
  }

  if (isNoBid(inputCode)) {
    return TransactionType.NoBid
  }

  if (isInternalTransfer(inputCode)) {
    return TransactionType.Transfer
  }

  if (isPassedIn(inputCode)) {
    return TransactionType.PassedIn
  }

  return TransactionType.Sold
}

function isValid(saleResultInputCode: string): boolean {
  return (
    (isSold(saleResultInputCode) ||
      isWithdrawn(saleResultInputCode) ||
      isNoBid(saleResultInputCode) ||
      isInternalTransfer(saleResultInputCode) ||
      isPassedIn(saleResultInputCode)) &&
    priceInvalid(saleResultInputCode) === false
  )
}

function requiresDestinationOrganisation(saleResultInputCode: string): boolean {
  return (
    isPassedIn(saleResultInputCode) ||
    isInternalTransfer(saleResultInputCode) ||
    isSold(saleResultInputCode)
  )
}

function isWithdrawn(inputCode: string): boolean {
  return withdrawnExpression.test(inputCode)
}

function isNoBid(inputCode: string): boolean {
  return noBidExpression.test(inputCode)
}

function isInternalTransfer(inputCode: string): boolean {
  return internalTransferExpression.test(inputCode)
}

function isPassedIn(inputCode: string): boolean {
  return passedInExpression.test(inputCode)
}

function isSold(inputCode: string): boolean {
  return soldExpression.test(inputCode)
}

function priceInvalid(inputCode: string): boolean {
  const price = getPrice(inputCode)
  if (!price) {
    return false
  } else {
    return price >= 1000000
  }
}

function getPrice(inputCode: string): number | null {
  const priceMatches = inputCode.match(priceExpression)
  if (priceMatches) {
    return Number(priceMatches[0])
  }
  return null
}

function getOriginCode(inputCode: string): string {
  if (!isValid(inputCode) && isInternalTransfer(inputCode)) {
    return ''
  }

  const destinationCodeMatches = inputCode.match(internalTransferExpression)

  if (destinationCodeMatches) {
    return destinationCodeMatches[1].toUpperCase()
  }

  return ''
}

function getDestinationCode(inputCode: string): string {
  if (!isValid(inputCode)) {
    return ''
  }

  const destinationCodeMatches = isInternalTransfer(inputCode)
    ? inputCode.match(internalTransferExpression)
    : inputCode.match(soldExpression)

  if (destinationCodeMatches) {
    if (isInternalTransfer(inputCode)) {
      return destinationCodeMatches[2].toUpperCase()
    } else {
      return destinationCodeMatches[1].toUpperCase()
    }
  }

  return ''
}

function getInputCodeFromTransaction(
  transaction: Transaction,
  originOrganisation?: Organisation,
  destinationOrganisation?: Organisation,
): string {
  if (!transaction) {
    return ''
  }

  switch (transaction.type) {
    case TransactionType.NoBid: {
      return 'NB'
    }
    case TransactionType.WithDrawn: {
      return 'WD'
    }
    case TransactionType.Transfer: {
      return originOrganisation && destinationOrganisation
        ? `${
            originOrganisation.code
              ? originOrganisation.code
              : originOrganisation.id
          } ${
            destinationOrganisation.code
              ? destinationOrganisation.code
              : destinationOrganisation.id
          }`.toUpperCase()
        : ''
    }
    case TransactionType.PassedIn: {
      return destinationOrganisation
        ? `${transaction.price}${
            destinationOrganisation.code
              ? destinationOrganisation.code
              : destinationOrganisation.id
          } P`.toUpperCase()
        : ''
    }
    case TransactionType.Sold: {
      if (transaction.price === 0) {
        return ''
      }

      return destinationOrganisation
        ? `${transaction.price}${
            destinationOrganisation.code
              ? destinationOrganisation.code
              : destinationOrganisation.id
          }`.toUpperCase()
        : ''
    }
    default: {
      return ''
    }
  }
}

export default {
  getOriginCode,
  getDestinationCode,
  getPrice,
  getInputCodeOutcome,
  getInputCodeFromTransaction,
  requiresDestinationOrganisation,
  isValid,
  isInternalTransfer,
  isNoBid,
  isPassedIn,
  isSold,
  isWithdrawn,
  priceInvalid,
}

import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  OnDestroy,
} from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core'
import { Store, select } from '@ngrx/store'
import { AppState } from '@store/reducers'
import { SearchState } from '@store/reducers/search.reducer'
import { selectSearchState } from '@store/selectors'
import { Observable, Subscription } from 'rxjs'

import { locationsData } from '../../../../shared/data/locations.data'
import { ClearSearch, SetSearchLocation, SetSearchDate } from '@store/actions/search.actions'

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
}

@Component({
  selector: 'app-sales-filter',
  templateUrl: './sales-filter.component.html',
  styleUrls: ['./sales-filter.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class SalesFilterComponent implements OnInit, OnDestroy {
  search$: Observable<SearchState>
  private searchSubscription: Subscription
  form: UntypedFormGroup
  locations = locationsData
  @Output() searchEntered: EventEmitter<any> = new EventEmitter()

  constructor(private fb: UntypedFormBuilder, private store: Store<AppState>) {
    this.form = this.fb.group({
      locationId: [null],
      date: [null],
    })
  }

  ngOnInit() {
    this.search$ = this.store.pipe(select(selectSearchState))
    this.searchSubscription = this.search$.subscribe(data => {
      if (data.locationId) {
        this.form.patchValue({ locationId: data.locationId })
      }

      if (data.date) {
        this.form.patchValue({ date: data.date })
      }
    })
  }

  ngOnDestroy() {
    this.searchSubscription.unsubscribe()
  }

  onLocationSelect(event: any) {
    this.store.dispatch(new SetSearchLocation({ locationId: event.target.value }))
  }

  onDateSelect(event: any) {
    this.store.dispatch(new SetSearchDate({ date: event.target.value }))
  }

  search() {
    this.searchEntered.emit()
  }

  clearFilters() {
    this.store.dispatch(new ClearSearch())
    this.form.patchValue({ locationId: null, date: null })
    this.search()
  }
}

import { HttpHeaders } from '@angular/common/http'
import { Observable, of } from 'rxjs'

export abstract class BaseApiService {
  readonly token: string
  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
  }
  readonly endpoint: string

  protected handleError<T>(operation = 'operation', result?: T) {
    return (error: any, caught: Observable<T>): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error, caught) // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result as T)
    }
  }
}

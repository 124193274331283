import { Component, OnInit, OnDestroy } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { AppState } from '@store/store.interface'
import { Actions } from '@store/actions'
import { OrganisationsCleared, OrganisationsCancel } from '@store/actions/organisations.actions'

import {
  dataFetchHasError,
  dataFetchIsLoading,
  dataFetchLoaded,
  dataFetchErrorMessage,
  isLoggedIn,
  dataFetchMessage
} from '@store/selectors'

import { SaleForm } from '@shared/models/sale-form.model'
import { trigger, style, animate, transition } from '@angular/animations'
import { Router } from '@angular/router'
import { combineLatest, Subscription } from 'rxjs'

import {
  Dismiss
} from '@store/actions/data-fetch.actions'

@Component({
  selector: 'app-root',
  animations: [
    trigger(
      'modal', [
        transition(':leave', [
          style({ opacity: 1 }),
          animate('100ms', style({ opacity: 0 }))
        ])
      ]
    ),
    trigger(
      'zoomAnimation', [
        transition(':enter', [
          style({ transform: 'scale(0.875)', opacity: 0 }),
          animate('75ms', style({ transform: 'scale(1)', opacity: 1 }))
        ])
      ]
    )
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'awh-trader-sale-creator'
  creatingSale = false
  saleForm: SaleForm
  dataFetchLoading: boolean
  dataFetchLoaded: boolean
  dataFetchHasError: boolean
  errorMessage: string
  loadingMessage: string
  loggedIn = false
  storeSubscription: Subscription = new Subscription
  saleFormSubscription: Subscription = new Subscription
  editOrg = false

  constructor (private store: Store<AppState>, router: Router) {}

  ngOnInit () {
    this.saleFormSubscription = this.store.select('saleForm').subscribe(sale => {
      const showSales = ['in-progress', 'submitting', 'error']
      this.creatingSale = showSales.includes(sale.status)
      this.saleForm = sale
    })

    this.store.select('organisations').subscribe(organisations => {
      this.editOrg = (organisations.status === 'in-progress')
    })

    this.storeSubscription = combineLatest(
      this.store.pipe(select(dataFetchHasError)),
      this.store.pipe(select(dataFetchIsLoading)),
      this.store.pipe(select(dataFetchLoaded)),
      this.store.pipe(select(dataFetchErrorMessage)),
      this.store.pipe(select(dataFetchMessage)),
      this.store.pipe(select(isLoggedIn))
    ).subscribe(jointResults => {
      this.dataFetchHasError = jointResults[0]
      this.dataFetchLoading = jointResults[1]
      this.dataFetchLoaded = jointResults[2]
      this.errorMessage = jointResults[3]
      this.loadingMessage = jointResults[4]
      this.loggedIn = jointResults[5]
    })

  }

  cancelSaleForm () {
    this.store.dispatch(new Actions.SaleFormActions.SaleFormCancelSale())
    this.store.dispatch(new OrganisationsCancel())
  }

  dismissModal () {
    this.store.dispatch(new Dismiss())
  }

  ngOnDestroy () {
    this.storeSubscription.unsubscribe()
    this.saleFormSubscription.unsubscribe()
  }
}

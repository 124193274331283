import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { Error } from '@shared/models/error.model'
import { SaleRoomData } from '@shared/models/sale-room-data.model'
import { SaleDataService } from '@shared/services/sale-data.service'
import * as StoreAction from '@store/actions'

import { of } from 'rxjs'
import {
  catchError,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators'

import { Dismiss, ShowError, ShowLoading } from '../actions/data-fetch.actions'
import { SaleDataCancelled, SaleDataRequested } from '../actions/sale-data.actions'
import {
  SaleRoomBrokerDisableCancelled,
  SaleRoomBrokerEnableCancelled,
  SaleRoomBrokerEnableRequested,
  SaleRoomBrokerReorderRequested,
  SaleRoomDataActionTypes,
  SaleRoomDataCancelled,
  SaleRoomDataLoaded,
  SaleRoomDataLotsLoaded,
  SaleRoomDataLotsRequested,
  SaleRoomDataRequested,
  SaleRoomLotReorderCancelled,
  SaleRoomLotReorderRequested,
  SaleRoomLotsMoveRequested,
  SaleRoomDataDeleted,
} from '../actions/sale-room-data.actions'
import { SaleRoomDataError } from '../reducers/sale-room-data.reducer'
import { selectAuthState, selectSaleLoaded, selectSaleLots, selectSaleId } from '../selectors'
import { AppState } from '../store.interface'

@Injectable()
export class SaleRoomDataEffects {
  constructor(
    private actions$: Actions,
    private saleDataService: SaleDataService,
    private store: Store<AppState>,
    private router: Router,
  ) {}

  loadSaleRoomLots$ = createEffect ( () => this.actions$.pipe(
    ofType<SaleRoomDataLotsRequested>(
      SaleRoomDataActionTypes.SaleRoomDataLotsRequested,
    ),
    withLatestFrom(this.store.pipe(select(selectSaleLots))),
    tap(() => {
      this.showLoader('Fetching lots...')
    }),
    switchMap(([action]) => {
      return this.saleDataService
        .getLots(action.payload.saleId, action.payload.roomNumber)
        .pipe(
          catchError(err => {
            const error = <SaleRoomDataError>{
              hasError: true,
              message: `Error requesting sale data from API: ${err.message}`,
            }

            if (err.status !== 0) {
              this.showError(error)
            }

            this.store.dispatch(new SaleRoomLotReorderCancelled({ error }))
            return of([])
          }),
        )
    }),
    withLatestFrom(this.store.pipe(select(selectAuthState))),
    filter(data => {
      const [lots, authState] = data

      if (!authState.loggedIn) {
        return false
      }

      const lotsFound = lots && lots.length >= 1
      if (!lotsFound) {
        const error = <Error>{
          hasError: true,
          message: `No lot data could be found for this room.`,
        }
        this.showError(error)
        this.store.dispatch(new SaleDataCancelled({ error }))
      }
      return lotsFound
    }),
    map(data => {
      const lots = data[0]
      const payload = {
        lots,
      }

      this.dismissLoader()
      return new SaleRoomDataLotsLoaded(payload)
    }),
  )
  )

  loadSaleRoomData$ =  createEffect ( () => this.actions$.pipe(
    ofType<SaleRoomDataRequested>(
      SaleRoomDataActionTypes.SaleRoomDataRequested,
    ),
    withLatestFrom(this.store.pipe(select(selectSaleLoaded))),
    filter(([action, saleLoaded]) => {
      return !saleLoaded
    }),
    tap(() => {
      this.showLoader('Fetching room data...')
    }),
    switchMap(([action, saleRoomLoaded]) => {
      return this.saleDataService
        .getLots(action.payload.id, action.payload.roomNumber)
        .pipe(
          catchError(err => {
            const error = <SaleRoomDataError>{
              hasError: true,
              message: `Error requesting sale data from API: ${err}`,
            }

            if (err.status !== 0) {
              this.showError(error)
            }

            this.store.dispatch(new SaleRoomDataCancelled({ error }))
            return of([])
          }),
          map(
            (data): SaleRoomData => ({
              roomNumber: action.payload.roomNumber,
              saleId: action.payload.id,
              locationId: action.payload.locationId,
              season: action.payload.season,
              saleNo: action.payload.saleNo,
              startTime: action.payload.startTime,
              roomCount: action.payload.roomCount,
              lots: data,
              transactions: [],
              loaded: true,
              loading: false,
              error: null,
            }),
          ),
        )
    }),
    withLatestFrom(this.store.pipe(select(selectAuthState))),
    filter(data => {
      const [saleRoomData, authState] = data

      if (!authState.loggedIn) {
        return false
      }

      const { lots } = saleRoomData
      const lotsFound = !!lots && lots.length >= 1
      const dataFound = lotsFound

      if (!dataFound) {
        const error = <Error>{
          hasError: true,
          message: `No data could be found for this room.`,
        }
        this.showError(error)
        this.store.dispatch(new SaleDataCancelled({ error }))
      }

      return dataFound
    }),
    map(data => {
      this.dismissLoader()
      this.router.navigateByUrl('/sale-room')
      return new SaleRoomDataLoaded({ SaleRoomData: data[0] })
    }),
  )
  )

  reorderLots$ = createEffect ( () => this.actions$.pipe(
    ofType<SaleRoomLotReorderRequested>(
      SaleRoomDataActionTypes.SaleRoomLotReorderRequested,
    ),
    withLatestFrom(this.store.pipe(select(selectSaleLots))),
    tap(() => {
      this.showLoader('Reordering lots...')
    }),
    switchMap(([action]) => {
      this.roomNumber = action.payload.roomNumber
      this.saleId = action.payload.saleId

      return this.saleDataService.reorderLots(action.payload).pipe(
        catchError(err => {
          const error = <SaleRoomDataError>{
            hasError: true,
            message: err.error.message,
          }

          if (err.status !== 0) {
            this.showError(error)
          }

          this.store.dispatch(new SaleRoomLotReorderCancelled({ error }))
          return of(error)
        }),
      )
    }),
    filter(response => {
      return !response || !response.hasError
    }),
    map(() => {
      this.dismissLoader()
      return this.reloadLots()
    }),
  )
  )

  moveSaleRoomLots$ = createEffect ( () => this.actions$.pipe(
    ofType<SaleRoomLotsMoveRequested>(
      SaleRoomDataActionTypes.SaleRoomLotsMoveRequested,
    ),
    withLatestFrom(this.store.pipe(select(selectSaleLots))),
    tap(() => {
      this.showLoader('Moving lots to other room...')
    }),
    switchMap(([action]) => {
      this.roomNumber = action.payload.roomNumber
      this.saleId = action.payload.saleId

      return this.saleDataService.moveLots(action.payload).pipe(
        catchError(err => {
          const error = <SaleRoomDataError>{
            hasError: true,
            message: err.error.message,
          }

          if (err.status !== 0) {
            this.showError(error)
          }

          this.store.dispatch(new SaleRoomLotReorderCancelled({ error }))
          return of(error)
        }),
      )
    }),
    filter(response => {
      return !response || !response.hasError
    }),
    map(() => {
      this.dismissLoader()
      return this.reloadLots()
    }),
  )
  )

  reorderBrokers$ = createEffect ( () => this.actions$.pipe(
    ofType<SaleRoomBrokerReorderRequested>(
      SaleRoomDataActionTypes.SaleRoomBrokerReorderRequested,
    ),
    withLatestFrom(this.store.pipe(select(selectSaleLots))),
    tap(() => {
      this.showLoader('Reordering brokers...')
    }),
    switchMap(([action]) => {
      this.roomNumber = action.payload.roomNumber
      this.saleId = action.payload.saleId

      return this.saleDataService.reorderBrokers(action.payload).pipe(
        catchError(err => {
          const error = <SaleRoomDataError>{
            hasError: true,
            message: err.error.message,
          }

          if (err.status !== 0) {
            this.showError(error)
          }

          this.store.dispatch(new SaleRoomLotReorderCancelled({ error }))
          return of(error)
        }),
      )
    }),
    filter(response => {
      return !response || !response.hasError
    }),
    map(() => {
      this.dismissLoader()
      return this.reloadLots()
    }),
  )
  )

  enableBroker$ = createEffect ( () => this.actions$.pipe(
    ofType<SaleRoomBrokerEnableRequested>(
      SaleRoomDataActionTypes.SaleRoomBrokerEnableRequested,
    ),
    switchMap(action => {
      this.roomNumber = action.payload.roomNumber
      this.saleId = action.payload.saleId
      this.showLoader('Enabling broker...')
      return this.saleDataService
        .enableBroker(action.payload.saleId, action.payload.brokerId)
        .pipe(
          catchError(err => {
            const error = <SaleRoomDataError>{
              hasError: true,
              message: err.error.message,
            }

            if (err.status !== 0) {
              this.showError(error)
            }

            this.store.dispatch(new SaleRoomBrokerEnableCancelled({ error }))
            return of(error)
          }),
        )
    }),
    filter(response => {
      return !response || !response.hasError
    }),
    map(() => {
      this.dismissLoader()
      return this.reloadLots()
    }),
  )
  )

  disableBroker$ =  createEffect ( () =>  this.actions$.pipe(
    ofType<SaleRoomBrokerEnableRequested>(
      SaleRoomDataActionTypes.SaleRoomBrokerDisableRequested,
    ),
    switchMap(action => {
      this.roomNumber = action.payload.roomNumber
      this.saleId = action.payload.saleId
      this.showLoader('Disabling broker...')
      return this.saleDataService
        .disableBroker(action.payload.saleId, action.payload.brokerId)
        .pipe(
          catchError(err => {
            const error = <SaleRoomDataError>{
              hasError: true,
              message: err.error.message,
            }

            if (err.status !== 0) {
              this.showError(error)
            }

            this.store.dispatch(new SaleRoomBrokerDisableCancelled({ error }))
            return of(error)
          }),
        )
    }),
    filter(response => {
      return !response || !response.hasError
    }),
    map(() => {
      this.dismissLoader()
      return this.reloadLots()
    }),
  )
  )

  deleteSale$ = createEffect ( () => this.actions$.pipe(
    ofType<SaleRoomDataDeleted>(
      SaleRoomDataActionTypes.SaleRoomDataDeleted,
    ),
    withLatestFrom(this.store.pipe(select(selectSaleId))),
    tap(() => {
      this.showLoader('Delete sale ...')
    }),
    switchMap(([action]) => {
      return this.saleDataService
        .deleteSale(parseInt(action.payload.id, 10))
        .pipe(
          catchError(err => {
            const error = <SaleRoomDataError>{
              hasError: true,
              message: `Error delete sale data from API: ${err.message}`,
            }

            alert(`Error delete sale data from API: ${err.message}, please try it later`)
            /*
            if (err.status !== 0) {
              this.showError(error)
            }
            */
            return of([])
          }),
          tap(() => {
            if ( action.payload.sale !== undefined && action.payload.sale !== null) {
              this.store.dispatch(new StoreAction.Actions.SaleFormActions.SaleFormCreateSale(action.payload.sale))
            }
          }),
        )
    }),

    map(data => {
      this.dismissLoader()
      return new SaleDataRequested()
    }),
  )
  )
  private roomNumber: number
  private saleId: string

  showLoader(message: string = 'Loading') {
    this.store.dispatch(new ShowLoading(message))
  }

  showError(error) {
    this.store.dispatch(new ShowError(error))
  }

  dismissLoader() {
    this.store.dispatch(new Dismiss())
  }

  reloadLots() {
    const payload = {
      saleId: this.saleId,
      roomNumber: this.roomNumber,
    }

    return new SaleRoomDataLotsRequested(payload)
  }
}

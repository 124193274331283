import * as SaleFormActions from '../actions/sale-form.actions'
import { SaleForm } from '@shared/models/sale-form.model'
import { SaleFormData } from '@shared/models/sale-form-data.model'

export interface SaleFormState {
  status: string
  statusMessage?: string
  data: SaleFormData
}


export const initialState: SaleForm = {
  status: 'null',
  data: null
}

export function saleFormReducer(state: SaleFormState = initialState, action: SaleFormActions.SaleFormActions): SaleFormState {

  if (!action || !action.type) {
    return initialState
  }

  switch (action.type) {
    case SaleFormActions.SaleFormActionTypes.SaleFormAddSale:
      return {
        ...state,
        status: 'in-progress'
      }

    case SaleFormActions.SaleFormActionTypes.SaleFormCancelSale:
      return initialState

    case SaleFormActions.SaleFormActionTypes.SaleFormCreateSale:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        },
        status: 'submitting'
      }

    case SaleFormActions.SaleFormActionTypes.SaleFormEmitSaleError:
      return {
        ...state,
        status: 'error',
        statusMessage: action.error
      }

    case SaleFormActions.SaleFormActionTypes.SaleFormReEnterSale:
      return {
        ...state,
        status: 'in-progress'
      }

    case SaleFormActions.SaleFormActionTypes.SaleFormStarted:
      return {
        ...state,
        status: 'sale-started'
      }

    case SaleFormActions.SaleFormActionTypes.SaleFormOverwrite:
      return {
        ...state,
        status: 'overwrite-sale'
      }

    case SaleFormActions.SaleFormActionTypes.SaleFormVerifySale:
      return initialState // The side effect for this is to add the full sale to sales array in store

    case SaleFormActions.SaleFormActionTypes.SaleFormCleared:
      return initialState

    default:
      return state
  }
}

import { Injectable } from '@angular/core'
import { Actions, createEffect , ofType } from '@ngrx/effects'
import { Router } from '@angular/router'
import { map } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { AppState } from '../store.interface'

import { SaleDataCleared } from '../actions/sale-data.actions'
import { OrganisationsCleared } from '../actions/organisations.actions'
import { DataFetchReset } from '../actions/data-fetch.actions'
import { SaleFormCleared} from '../actions/sale-form.actions'
import { SaleRoomDataCleared } from '../actions/sale-room-data.actions'
import {
  AuthActionTypes,
  Logout,
} from '../actions/auth.actions'

@Injectable()
export class AuthEffects {

  logout$ = createEffect
( () =>  this.actions$.pipe(
    ofType<Logout>(AuthActionTypes.LogoutAction),
    map(() => {
      this.router.navigateByUrl('/login')
      this.store.dispatch(new DataFetchReset())
      this.store.dispatch(new SaleFormCleared())
      this.store.dispatch(new SaleRoomDataCleared())
      this.store.dispatch(new OrganisationsCleared())
      return new SaleDataCleared()
    }),
  )
  )

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<AppState>) {}
}

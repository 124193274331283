import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http'

import { Store } from '@ngrx/store'
import { AppState } from '@store/store.interface'
import { Actions } from '@store/actions'
import { throwError } from 'rxjs'
import { catchError, first, flatMap } from 'rxjs/operators'
import { Observable } from 'rxjs/Observable'

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 0) {
            this.store.dispatch(
              new Actions.DataFetchActions.ShowError({
                hasError: true,
                message:
                  'The server took too long to respond. Please try and reload the data by refreshing the page, \
                  or by clicking the sale roster link in the top left corner.',
              }),
            )
          }
        }
        return throwError(err)
      }),
    )
  }
}

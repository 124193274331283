import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core'

@Directive({ selector: '[appFocus]' })
export class FocusDirective implements OnChanges {
  @Input()
  isFocused: boolean

  constructor(private hostElement: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.isFocused) {
      this.hostElement.nativeElement.value = ''
      this.hostElement.nativeElement.focus()
    }
  }
}

import { Component, OnInit, Input, Output, ViewChild, EventEmitter, ElementRef } from '@angular/core'
import { environment } from '@environments/environment'

@Component({
  selector: 'lot-row',
  templateUrl: './lot-row.component.html',
  styleUrls: ['./lot-row.component.scss']
})
export class LotRowComponent implements OnInit {
  @ViewChild('transactionCodeInput') transactionCodeInput: ElementRef
  @Output() transactionCodeEntered: EventEmitter<string> = new EventEmitter()
  @Input() position: number
  @Input() enabled: boolean
  @Input() lotNumber: string
  @Input() originalSellingOrganisation: string
  @Input() inputCode: string
  @Input() brokerImage: string
  @Input() brokerId: string
  @Input() cssClass: string
  @Input() price: number
  @Input() transactionText: string
  @Input() buyer: string
  @Input() selected: boolean
  @Input() status: string
  @Input() woolTypeGroup: string
  imageShowing = false

  constructor() {}

  ngOnInit() {}

  onTransactionCodeEntered(resultCode: string): void {
    const resultCodeUpperCase = resultCode.toUpperCase()
    this.transactionCodeEntered.emit(resultCodeUpperCase)
  }

  onInputFocus(event: any) {
    event.target.value = ''
    event.target.placeholder = this.inputCode
  }

  onInputFocusOut(event: any) {
    event.target.placeholder = ''
    event.target.value = this.inputCode
  }

  showImage() {
    this.imageShowing = true
  }

  hideImage() {
    this.imageShowing = false
  }
}

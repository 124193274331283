import {Directive, Input, HostBinding, HostListener } from '@angular/core'
@Directive({
  selector: 'img[default]'
})

 export class ImagePreloadDirective {
  @Input() default: string
  @HostBinding('class') className
  @HostBinding('src') imgsrc = 'src'
  @HostListener('error') onError() {
    this.updateUrl()
  }
  @HostListener('load') onload() {
    this.load()
  }

  updateUrl () {
    this.imgsrc = this.default
  }

  load () {
    this.className = 'image-loaded'
  }
}

import { DataFetchActionsTypes, DataFetchReset, Dismiss, ShowError, ShowLoading } from '../actions/data-fetch.actions'

export interface Error {
  hasError: boolean
  message: string
}

export interface DataFetchState {
  loaded: boolean
  loading: boolean
  message: string
  error: Error
}

export const initialState: DataFetchState = {
  loaded: false,
  loading: false,
  message: '',
  error: {
    hasError: false,
    message: ''
  }
}

export function dataFetchReducer (
  state = initialState,
  action:
    | ShowLoading
    | ShowError
    | Dismiss
    | DataFetchReset
): DataFetchState {

  if (!action || !action.type) {
    return initialState
  }

  switch (action.type) {
    case DataFetchActionsTypes.showLoading:
      return {
        ...state,
        loaded: false,
        loading: true,
        message: action.message
      }

    case DataFetchActionsTypes.showError:
      return {
        loaded: true,
        loading: false,
        message: '',
        error: {
          hasError: true,
          message: action.error.message,
        }
      }

    case DataFetchActionsTypes.dismiss:
      return {
        ...state,
        error: {
          hasError: false,
          message: ''
        },
        loaded: true,
        loading: false
      }

    case DataFetchActionsTypes.reset:
      return initialState

    default:
      return state
  }
}

<div class="sales-filter-container">
  <div class="content">
    <form [formGroup]="form"
          class="sales-filter-form form">
      <div>
        <select (change)="onLocationSelect($event)"
                formControlName="locationId">
          <option [ngValue]="null">Select Location</option>
          <option [value]="location.key"
                  *ngFor="let location of locations | keyvalue"> {{ location.value.name }}</option>
        </select>
      </div>
      <div>
        <input (click)="salesDateDatepicker.open()"
               (dateChange)="onDateSelect($event)"
               type="text"
               placeholder="Select Date To Filter From"
               formControlName="date"
               [matDatepicker]="salesDateDatepicker">
        <mat-datepicker #salesDateDatepicker></mat-datepicker>
      </div>
      <div>
        <button (click)="search()"
                [disabled]="!form.valid">
          <div class="search">Search</div>
        </button>
        <button (click)="clearFilters()">
          <div class="clear">Clear Filters</div>
        </button>
      </div>
    </form>
  </div>
</div>

import { Component, OnInit, Input } from '@angular/core'
import { Router } from '@angular/router'
import { SelectedSale } from '@shared/models/selected-sale.model'
import { Store } from '@ngrx/store'
import { AppState } from '@store/store.interface'
import { Actions } from '@store/actions'
import { SaleRoomSummary } from '@shared/models/sale-room-summary.model'
import { SaleRoomDataRequested, SaleRoomDataDeleted } from '@store/actions/sale-room-data.actions'
import { trigger, style, animate, transition } from '@angular/animations'

@Component({
  selector: 'app-sale',
  templateUrl: './sale.component.html',
  animations: [
    trigger(
      'modal', [
        transition(':leave', [
          style({ opacity: 1 }),
          animate('100ms', style({ opacity: 0 }))
        ])
      ]
    ),
    trigger(
      'zoomAnimation', [
        transition(':enter', [
          style({ transform: 'scale(0.875)', opacity: 0 }),
          animate('75ms', style({ transform: 'scale(1)', opacity: 1 }))
        ])
      ]
    )
  ],
  styleUrls: ['./sale.component.scss']
})
export class SaleComponent implements OnInit {
  showConfirm = false
  startTime: Date
  @Input() sale: SelectedSale
  constructor(private store: Store<AppState>, private router: Router) { }

  ngOnInit() { }

  viewRoom(roomIndex: number) {
    this.store.dispatch(new SaleRoomDataRequested({
      id: this.sale.id,
      locationId: this.sale.locationId,
      saleNo: this.sale.saleNo,
      season: this.sale.season,
      roomNumber: roomIndex + 1,
      startTime: this.sale.startTime,
      roomCount: this.sale.rooms.length
    }))
  }

  deleteSale(roomIndex: number) {
    this.startTime = new Date(this.sale.startTime)
    this.showConfirm = true
    /*if (confirm(`Sale ${this.sale.locationId}${this.sale.saleNo} for ${str.toDateString()} has been selected for deletion. Continue?`)) {
      this.store.dispatch(new SaleRoomDataDeleted({
        id: this.sale.id,
        sale: null,
      }))

    }*/
  }

  deleteThis() {
    this.showConfirm = false
    this.store.dispatch(new SaleRoomDataDeleted({
      id: this.sale.id,
      sale: null,
    }))
  }

  cancelThis() {
    this.showConfirm = false
  }

}

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@environments/environment'
import { Observable } from 'rxjs'
import { BaseApiService } from '../base-api.service'
import { Store, select } from '@ngrx/store'
import { AppState } from '@store/store.interface'
import { selectToken } from '@store/selectors'


@Injectable()
export class AuthService extends BaseApiService {
  readonly endpoint = 'auth/login'
  token
  private loginUrl = `${environment.apiUrl}/${this.endpoint}`

  constructor(
    private http: HttpClient,
    private store: Store<AppState>) {
    super()
    this.store.pipe(select(selectToken)).subscribe(token => this.token = token)
  }

  login(username: string, password: string): Observable<string> {
    return this.http.post<string>(
      this.loginUrl,
      { username, password, locationId: 'ROSTER_CREATOR' },
      this.httpOptions
    )
  }

  public getToken(): string {
    return this.token
  }
}

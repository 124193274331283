import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { SaleRoomSummary } from '@shared/models/sale-room-summary.model'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import * as moment from 'moment'
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker'
import { SaleDataEditRequested } from '@store/actions/sale-data.actions'
import { Store } from '@ngrx/store'
import { AppState } from '@store/store.interface'

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY'
  }
}
@Component({
  selector: 'app-room-summary-content',
  templateUrl: './room-summary-content.component.html',
  styleUrls: ['./room-summary-content.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [ MAT_DATE_LOCALE ] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class RoomSummaryContentComponent implements OnInit {
  @Input() saleRoomSummary: SaleRoomSummary
  @Input() roomNumber: number
  @Input() locationId: string
  @Input() saleNo: string
  @Input() date: string
  @Input() saleId: string
  @Output() viewRoom = new EventEmitter()
  time: string

  pickerTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#f7f7f7',
      buttonColor: '#4a90e2'
    },
    dial: {
      dialBackgroundColor: '#4a90e2',
    },
    clockFace: {
      clockFaceBackgroundColor: '#e7e7e7',
      clockHandColor: '#4a90e2',
      clockFaceTimeInactiveColor: '#555'
    }
  }

  constructor (private store: Store<AppState>) { }

  ngOnInit () {
    this.time = this.startTime
  }

  get startTime() {
    return moment.utc(this.date).format('hh:mm a')
  }

  setTime(startTime) {
    const payload = {
      startTime,
      saleId: this.saleId,
      roomNumber: this.roomNumber
    }
    this.store.dispatch(new SaleDataEditRequested(payload))
  }

  emitViewRoom() {
    this.viewRoom.emit()
  }

}

import { Injectable } from '@angular/core'
import { Actions, createEffect , ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { of } from 'rxjs'
import {
  catchError,
  map,
  filter,
  switchMap,
  withLatestFrom,
  concatMap
} from 'rxjs/operators'

import { AppState } from '../store.interface'

import {
  TransactionActionTypes,
  AddTransaction
} from '../actions/transaction.actions'

import {
  ShowError,
  ShowLoading,
  Dismiss
} from '../actions/data-fetch.actions'


import { SaleRoomDataError } from '../reducers/sale-room-data.reducer'
import { selectSaleLoaded, selectAuthState } from '../selectors'
import { SaleDataService } from '@shared/services/sale-data.service'

@Injectable()
export class TransactionEffects {

  addTransaction$ = createEffect ( () =>  this.actions$.pipe(
    ofType<AddTransaction>(TransactionActionTypes.AddTransaction),
    withLatestFrom(this.store.pipe(select(selectSaleLoaded))),
    switchMap(([action]) => {
      this.showLoader()
        return of(...action.transactions)
          .pipe(
            concatMap((transaction) => {
              return this.saleDataService
                .addTransaction(
                  transaction.saleId,
                  transaction.roomNumber,
                  transaction.transaction
                )
              .pipe(
                catchError(err => {
                  const error = <SaleRoomDataError>{
                    hasError: true,
                    message: `Error adding transaction: ${err.message}`,
                  }

                  if (err.status !== 0) {
                    this.showError(error)
                  }

                  return of(err)
                })
              )
            })
          )
    }),
    filter(response => {
      return !response || !response.hasError
    }),
    map(() => {
      // We'll dispatch a lot reload from the other PR
      return new Dismiss()
    })
  )
  )

  showLoader() {
    this.store.dispatch(new ShowLoading())
  }

  showError(error) {
    this.store.dispatch(new ShowError(error))
  }

  dismissLoader() {
    this.store.dispatch(new Dismiss())
  }

  constructor(
    private actions$: Actions,
    private saleDataService: SaleDataService,
    private store: Store<AppState>,
  ) {}
}

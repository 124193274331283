<div class="table-container">
  <table-header (toggleView)="switchView($event)"></table-header>
  <div class="table-rows"
       *ngIf="tableView === 'lots'">
    <lot-row class="row"
             *ngFor="let row of rows; let rowIndex = index"
             #saleInputTableRow
             [position]="rowIndex + 1"
             [selected]="row === selectedLotRow"
             [ngClass]="getRowClass(row)"
             [cssClass]="getRowClass(row)"
             (focusout)="onRowFocusOut(row)"
             [enabled]="row.enabled"
             [inputCode]="row.inputCode"
             [lotNumber]="row.lotNumber"
             [brokerImage]="row.brokerImage"
             [brokerId]="row.brokerId"
             [originalSellingOrganisation]="row.originalSellingOrganisation"
             [price]="row.price"
             [status]="row.status"
             [buyer]="row.buyer"
             [woolTypeGroup]="row.woolTypeGroup"
             (keydown.arrowdown)="selectRowIndexOffset(rowIndex, 1)"
             (keydown.arrowup)="selectRowIndexOffset(rowIndex, -1)"
             (keydown.shift.arrowdown)="selectRowIndexOffset(rowIndex, 10)"
             (keydown.shift.arrowup)="selectRowIndexOffset(rowIndex, -10)"
             (keyup.tab)="selectRowIndexOffset(rowIndex, 0)"
             (keyup.shift.tab)="selectRowIndexOffset(rowIndex, 0)"
             (transactionCodeEntered)="onTransactionCodeEntered($event, rowIndex, row)"
             [class.row-odd]="rowIndex % 2 !== 0"
             [class.active]="row === selectedLotRow"
             (click)='onSelectRow(rowIndex)'></lot-row>
  </div>
  <div class="table-rows"
       *ngIf="tableView === 'brokers'"
       cdkDropList
       [cdkDropListData]="brokers"
       (cdkDropListDropped)="drop($event)">
    <broker-row cdkDrag
                *ngFor="let row of brokerRows; let rowIndex = index"
                [enabled]="row.enabled"
                [cdkDragData]="row"
                [brokerId]="row.brokerId"
                [brokerCode]="row.brokerCode"
                [woolTypeGroup]="row.woolTypeGroup"
                [lotCount]="row.lotCount"
                [baleCount]="row.baleCount"
                [order]="rowIndex"
                [maxPosition]="brokerRows.length"
                (reOrderBroker)="onReOrderBroker($event, rowIndex, row)"
                (enabledChanged)="onEnabledChanged($event, rowIndex, row)">
      <div class="drag-placeholder"
           *cdkDragPlaceholder></div>
      <img height="26"
           class="drag-icon"
           cdkDragHandle
           src="assets/img/icons/draggable.svg" />
    </broker-row>
  </div>
</div>

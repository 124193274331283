<div class="header-container">
  <div class="header"
       [ngClass]="{'lots': tableView === 'lots', 'brokers': tableView === 'brokers' }">
    <div *ngIf="tableView === 'brokers'">Enabled</div>
    <div *ngIf="tableView === 'brokers'">Order</div>
    <div *ngIf="tableView === 'brokers'">Broker</div>
    <div *ngIf="tableView === 'brokers'">Lots</div>
    <div *ngIf="tableView === 'brokers'">Bales</div>
    <div *ngIf="tableView === 'brokers'">Wool Type</div>
    <div *ngIf="tableView === 'lots'">Position</div>
    <div *ngIf="tableView === 'lots'">Lot</div>
    <div *ngIf="tableView === 'lots'">Broker</div>
    <div *ngIf="tableView === 'lots'">Input Code</div>
    <div *ngIf="tableView === 'lots'">Price</div>
    <div *ngIf="tableView === 'lots'"></div>
    <div *ngIf="tableView === 'lots'">Buyer</div>
    <div *ngIf="tableView === 'lots'">Wool Type</div>
    <div class="lot-broker-switch">
      <app-slide-toggle (toggle)="switchView($event)"
                        aValue="Lots"
                        bValue="Brokers"
                        [selected]="1"></app-slide-toggle>
    </div>
  </div>
</div>

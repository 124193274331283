
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router'
import { environment } from '@environments/environment'
import { Observable } from 'rxjs'
@Injectable({
  providedIn: 'root'
})
export class ResolverService implements Resolve<any> {
  constructor(public http: HttpClient) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.getData(route.url)
  }

  getData(page: any = 'about') {
    const routes = {
      'sales-manager': `sales`
    }

    const path = routes[page]

    return this.http.get(`${environment.apiUrl}/${path}`)
  }
}

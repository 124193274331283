import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'removeLeadingZeroes',
})
export class RemoveLeadingZeroesPipe implements PipeTransform {
  transform(val: string): string {
    if (val !== undefined && val !== null) {
      return val.replace(/^(0+)(\d+)$/gm, '$2')
    } else {
      return ''
    }
  }
}

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { environment } from '@environments/environment'
import { TransactionType } from '@shared/enums/transaction-type.enum'
import { BrokerGroupedByWooltype } from '@shared/models/broker-grouped-by-wooltype.model'
import { BrokerSummary } from '@shared/models/broker-summary.model'
import { ReorderBrokersPayload } from '@shared/models/brokers-reorder.model'
import { CreateTransaction } from '@shared/models/create-transaction.model'
import { Lot } from '@shared/models/lot.model'
import { Organisation } from '@shared/models/organisation.model'
import { SaleRoomData } from '@shared/models/sale-room-data.model'
import { Transaction } from '@shared/models/transaction.model'
import InputCodeParser from '@shared/modules/inputcodeparser.module'

import { LotTableRowState } from './lot-row/lot-row-state.enum'
import { LotRow } from './lot-row/lot-row.model'
import { LotRowFilterPipe } from './pipes/lot-row-filter.pipe'
import { BrokerEnabledChanged } from '@shared/models/broker-enabled-changed.model'

interface RowStatusDisplayItem {
  text: string
  cssClass: string
}

type DisplayItemMap<T> = { [key in LotTableRowState]: RowStatusDisplayItem }

@Component({
  selector: 'room-table',
  templateUrl: './room-table.component.html',
  styleUrls: ['./room-table.component.scss'],
})
export class RoomTableComponent implements OnInit, OnChanges {
  @Input() saleRoomData: SaleRoomData
  @Input() lots: Lot[] = []
  @Input() brokersSummary: BrokerSummary[] = []
  @Input() brokersGroupedByWoolType: BrokerGroupedByWooltype[] = []
  @Input() brokers: Organisation[] = []
  @Input() buyers: Organisation[] = []
  @Input() rows: LotRow[] = []
  brokerId: string
  lotNumber: string

  @Output() transactionEntered: EventEmitter<
    CreateTransaction[]
  > = new EventEmitter()
  @Output() toggleView: EventEmitter<string> = new EventEmitter()
  @Output() brokerReorder: EventEmitter<
    ReorderBrokersPayload
  > = new EventEmitter()
  @Output() enabledChanged: EventEmitter<
    BrokerEnabledChanged
  > = new EventEmitter()

  selectedLotRow: LotRow
  tableView = 'brokers'
  brokerRows: any[]

  statusDisplayItems: DisplayItemMap<TransactionType> = {
    [LotTableRowState.Sold]: { text: 'Sold', cssClass: 'sold' },
    [LotTableRowState.PassedIn]: {
      text: 'Passed In',
      cssClass: 'passed-in',
    },
    [LotTableRowState.WithDrawn]: {
      text: 'Withdrawn',
      cssClass: 'withdrawn',
    },
    [LotTableRowState.NoBid]: { text: 'No Bid', cssClass: 'no-bid' },
    [LotTableRowState.Transfer]: {
      text: 'Transfer',
      cssClass: 'transfer',
    },
    [LotTableRowState.InvalidCode]: {
      text: 'Error',
      cssClass: 'invalid-code',
    },
    [LotTableRowState.Unsold]: {
      text: '...',
      cssClass: 'unsold',
    },
  }

  constructor() {}

  ngOnInit() {
    this.rows = this.createTableRows(this.lots)
    this.createBrokerRows()
  }

  createBrokerRows() {
    this.brokerRows = this.brokersGroupedByWoolType.map(broker => {
      return {
        ...broker,
        brokerCode: this.getBrokerCode(broker.brokerId),
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    const { saleRoomData, brokersGroupedByWoolType } = changes

    if (
      !!saleRoomData &&
      saleRoomData.previousValue !== changes.saleRoomData.currentValue
    ) {
      this.rows = this.createTableRows(this.saleRoomData.lots)
    }

    if (
      !!brokersGroupedByWoolType &&
      brokersGroupedByWoolType.previousValue !==
        brokersGroupedByWoolType.currentValue
    ) {
      this.brokersGroupedByWoolType = brokersGroupedByWoolType.currentValue
      this.createBrokerRows()
    }
  }

  switchView(view) {
    this.tableView = view
    this.toggleView.emit(view)
  }

  onReOrderBroker(position: number, rowIndex: number, row: any): void {
    const order: string[] = this.brokers.map(brokerRow => brokerRow.brokerId)
    moveItemInArray(this.brokers, rowIndex, position - 1)
    const brokerId = row.brokerId
    const woolTypeGroup = row.woolTypeGroup
    const { saleId, roomNumber } = this.saleRoomData
    this.brokerReorder.emit({
      order,
      saleId,
      brokerId,
      woolTypeGroup,
      position,
      roomNumber,
    })
  }

  onEnabledChanged(enabledChanged: BrokerEnabledChanged) {
    this.enabledChanged.emit(enabledChanged)
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.brokers, event.previousIndex, event.currentIndex)
    const order: string[] = this.brokers.map(row => row.brokerId)
    const brokerId = event.item.data.brokerId
    const woolTypeGroup = event.item.data.woolTypeGroup
    const position = event.currentIndex + 1

    const { saleId, roomNumber } = this.saleRoomData

    this.brokerReorder.emit({
      order,
      saleId,
      brokerId,
      woolTypeGroup,
      position,
      roomNumber,
    })
  }

  createTableRows(lots: Lot[]): LotRow[] {
    const pipe = new LotRowFilterPipe()
    const lotRows = lots.map(lot => {
      let status = '...'
      let inputCode = ''
      let origin = lot.catalogueInformation.sellingOrganisation
      const woolTypeGroup = lot.catalogueInformation.woolTypeGroup
      let price = 0
      let destination = ''
      if (!!lot.transaction) {
        const originOrganisation =
          this.getBroker(lot.transaction.origin) ||
          this.getBuyer(lot.transaction.origin)
        const destinationOrganisation =
          this.getBroker(lot.transaction.destination) ||
          this.getBuyer(lot.transaction.destination)
        status = this.statusDisplayItems[lot.transaction.type].text
        inputCode = InputCodeParser.getInputCodeFromTransaction(
          lot.transaction,
          originOrganisation,
          destinationOrganisation,
        )
        origin = lot.transaction.origin
        price = +lot.transaction.price
        destination = this.getBuyerOrganisationName(lot.transaction.destination)
      }

      return <LotRow>{
        id: lot.id,
        lotNumber: lot.catalogueInformation.lotIdentity,
        originalSellingOrganisation: lot.originalSellingOrganisation,
        inputCode,
        brokerId: origin,
        enabled: lot.enabled,
        brokerImage: `${environment.s3Url}/broker-images/${this.getBrokerCode(
          origin,
        ).toLowerCase()}.png`,
        hasInputError: false,
        price,
        buyer: destination,
        status,
        woolTypeGroup,
      }
    })

    return pipe.transform(lotRows, this.brokerId, this.lotNumber)
  }

  onTransactionCodeEntered(
    code: string,
    rowIndex: number,
    row: LotRow,
    previousPayload: CreateTransaction[] = [],
  ): void {
    if (code.toUpperCase() === row.inputCode.toUpperCase()) {
      this.selectRowIndexOffset(rowIndex, 1)
      return
    }

    const codeUpperCase = code.toUpperCase()
    if (this.isValidTransaction(codeUpperCase)) {
      const destinationCode = InputCodeParser.requiresDestinationOrganisation(
        codeUpperCase,
      )
        ? InputCodeParser.getDestinationCode(codeUpperCase)
        : ''

      const destinationOrganisation =
        this.getBuyer(destinationCode) || this.getBroker(destinationCode)

      const transaction: Transaction = {
        id: null,
        lotId: row.id,
        type: InputCodeParser.getInputCodeOutcome(codeUpperCase),
        price: null,
        origin: previousPayload.length > 0
          ? previousPayload[0].destination
          : null,
        destination: destinationOrganisation
          ? destinationOrganisation.id
          : null,
        localTimestamp: new Date().toISOString(),
      }

      let buyerCode = ''

      if (InputCodeParser.isInternalTransfer(code)) {
        if (
          transaction.type !== TransactionType.Sold &&
          transaction.type !== TransactionType.Transfer
        ) {
          row.hasInputError = true
          return
        } else {
          buyerCode = InputCodeParser.getDestinationCode(codeUpperCase)
        }
      } else {
        buyerCode = InputCodeParser.getDestinationCode(codeUpperCase)
        row.price = InputCodeParser.getPrice(codeUpperCase)
        transaction.price = InputCodeParser.getPrice(codeUpperCase)
      }

      const buyer = this.getBroker(buyerCode) || this.getBuyer(buyerCode)
      let automaticTransferTo: string

      if (!!buyer) {
        automaticTransferTo = buyer.automaticTransferTo
      }

      row.buyer = buyer ? buyer.name : buyerCode

      this.selectRowIndexOffset(rowIndex, 1)
      let originOrganisation = this.getBroker(transaction.origin)
      if (!originOrganisation) {
        originOrganisation = this.getBuyer(transaction.origin)
      }
      row.inputCode = InputCodeParser.getInputCodeFromTransaction(
        transaction,
        originOrganisation,
        destinationOrganisation,
      )
      row.hasInputError = false
      row.status = this.statusDisplayItems[transaction.type].text

      const payload = [
        ...previousPayload,
        {
          lotId: row.id,
          price: row.price || 0,
          type: transaction.type,
          origin:
            previousPayload.length > 0
              ? previousPayload[0].destination
              : row.brokerId,
          destination: transaction.destination,
          localTimestamp: transaction.localTimestamp,
          automatic: previousPayload.length > 0 ? true : false,
        },
      ]

      if (
        !!buyer &&
        !!automaticTransferTo &&
        automaticTransferTo.length > 0 &&
        transaction.type === 0
      ) {
        return this.onTransactionCodeEntered(
          `${
            transaction.destination
          } ${buyer.automaticTransferTo.toUpperCase()}`,
          rowIndex,
          row,
          payload,
        )
      }

      return this.transactionEntered.emit(payload)
    } else {
      row.hasInputError = true
    }
  }

  getBuyerByCode(code: string): string {
    const buyer = this.buyers.find(singleBuyer => singleBuyer.code === code)
    return !!buyer ? buyer.name : code
  }

  private isValidTransaction(code: string) {
    const valid = InputCodeParser.isValid(code)
    if (InputCodeParser.requiresDestinationOrganisation(code)) {
      const destinationOrganisationCode = InputCodeParser.getDestinationCode(
        code,
      )

      if (!this.getBuyer(destinationOrganisationCode)) {
        // valid = false We need an API that will return proper data for this to work
      }
    }
    return valid
  }

  onSelectRow(rowIndex: number): void {
    this.selectRowIndexOffset(rowIndex, 0)
  }

  onRowFocusOut(row: LotRow) {
    row.hasInputError = false
  }

  getRowClass = (row: LotRow): string => {
    if (row.hasInputError && row === this.selectedLotRow) {
      return this.statusDisplayItems[LotTableRowState.InvalidCode].cssClass
    }

    return row.status
      .split(' ')
      .join('-')
      .toLowerCase()
  }

  getBrokerCode(id: string): string {
    const broker = this.brokers.find(
      currentBroker => currentBroker.brokerId === id,
    )
    return !!broker ? broker.brokerCode || id : id
  }

  getBuyer(inputCode: string): Organisation {
    return (
      this.buyers.find(buyer => buyer.id === inputCode) ||
      this.buyers.find(buyer => buyer.code === inputCode)
    )
  }

  getBroker(inputCode: string): Organisation {
    return (
      this.brokers.find(broker => broker.id === inputCode) ||
      this.brokers.find(broker => broker.code === inputCode)
    )
  }

  getBuyerOrganisationName(id: string): string {
    const returnedBuyer = this.buyers.find(buyer => buyer.id === id)
    return returnedBuyer ? returnedBuyer.name : null
  }

  getInputCode(transaction: Transaction): string {
    if (transaction) {
      let originOrganisation = this.getBroker(transaction.origin)
      if (!originOrganisation) {
        originOrganisation = this.getBuyer(transaction.origin)
      }
      const destinationOrganisation = this.getBuyer(transaction.destination)

      return InputCodeParser.getInputCodeFromTransaction(
        transaction,
        originOrganisation,
        destinationOrganisation,
      ).toUpperCase()
    } else {
      return ''
    }
  }

  selectRowIndexOffset(rowIndex: number, offset: number): void {
    const nextRowIndex = this.getNextRowIndex(
      rowIndex,
      offset,
      this.rows.length,
    )

    this.selectedLotRow = this.rows[nextRowIndex]
  }

  private getNextRowIndex(
    currentIndex: number,
    offset: number,
    numberOfRows: number,
  ): number {
    const maxIndex = numberOfRows - 1
    const minIndex = 0
    let nextRowIndex =
      currentIndex + offset < numberOfRows ? currentIndex + offset : maxIndex
    if (nextRowIndex < minIndex) {
      nextRowIndex = minIndex
    }

    return nextRowIndex
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { SaleRoomSummary } from '@shared/models/sale-room-summary.model'

@Component({
  selector: 'app-room-summary',
  templateUrl: './room-summary.component.html',
  styleUrls: ['./room-summary.component.scss']
})
export class RoomSummaryComponent implements OnInit {
  @Input() saleRoomSummary: SaleRoomSummary
  @Input() roomNumber: number
  @Input() date: string
  @Input() saleId: string
  @Output() viewRoom = new EventEmitter()
  constructor() { }

  ngOnInit() { }

  emitViewRoom() {
    this.viewRoom.emit()
  }

}

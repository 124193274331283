import * as SearchActions from '../actions/search.actions'

export interface SearchState {
  locationId: string
  date: string
  visible: boolean
}


export const initialState: SearchState = {
  locationId: null,
  date: null,
  visible: false
}

export function searchReducer(state: SearchState = initialState, action: SearchActions.SearchActions): SearchState {

  if (!action || !action.type) {
    return initialState
  }

  switch (action.type) {
    case SearchActions.SearchActionTypes.SetSearchLocationAction:
      return {
        ...state,
        locationId: action.payload.locationId
      }
    case SearchActions.SearchActionTypes.SetSearchDateAction:
      return {
        ...state,
        date: action.payload.date
      }
    case SearchActions.SearchActionTypes.ToggleVisibleAction:
      return {
        ...state,
        visible: !state.visible
      }
    case SearchActions.SearchActionTypes.ClearSearchAction:
      return {
        ...state,
        date: initialState.date,
        locationId: initialState.locationId
      }
    default:
      return state
  }
}

import { ResolverService } from '@shared/services/resolver/resolver.service'
import { AuthService } from '@shared/services/auth/auth.service'
import { SaleRoomGuard } from '@store/guards/sale-room.guard'
import { AuthGuard } from '@store/guards/auth.guard'

export const Services = [
  ResolverService,
  SaleRoomGuard,
  AuthGuard,
  AuthService
]

import { Injectable } from '@angular/core'
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Store, select } from '@ngrx/store'
import { AppState } from '@store/store.interface'
import { Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'

@Injectable()
export class SaleRoomGuard implements CanActivate {
  constructor(private store: Store<AppState>, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.pipe(
      select('selectedSaleRoom'),
      map(saleRoom => {
        if (!saleRoom.loaded) {
          this.router.navigateByUrl('/')
          return false
        }

        return true
      }),
      take(1)
    )
  }
}

import * as ReducerState from '@store/reducers'
import * as moment from 'moment'

import { Component, OnInit } from '@angular/core'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import { Dismiss, ShowError, ShowLoading } from '@store/actions/data-fetch.actions'
import { Observable, of } from 'rxjs'
import { Store, select } from '@ngrx/store'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import {concatMap, map} from 'rxjs/operators'

import { Actions } from '@store/actions'
import { AppState } from '@store/store.interface'
import { CalendarHeaderComponent } from '../calendar/header/header.component'
import { CreateSale } from '@shared/services/request-models/create-sale.model'
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker'
import { Organisation } from '@shared/models/organisation.model'
import { Organisations } from '@shared/models/organisations.model'
import { OrganisationsRequested } from '@store/actions/organisations.actions'
import { Sale } from '@shared/models/sale.model'
import { SaleDataService } from '@shared/services/sale-data.service'
import { SaleForm } from '@shared/models/sale-form.model'
import { SaleRoomDataDeleted } from '@store/actions/sale-room-data.actions'
import { sales } from '@mocks/data/sales'
import { selectBrokersAndBuyers } from '@store/selectors'

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY'
  }
}

@Component({
  selector: 'app-add-sale-input',
  templateUrl: './add-sale-input.component.html',
  styleUrls: ['./add-sale-input.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [ MAT_DATE_LOCALE ] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class AddSaleInputComponent implements OnInit {
  sale: SaleForm
  organisations: Organisations
  timeOptions: Array<any> = []
  calendarHeader = CalendarHeaderComponent
  mockSale: Sale = sales[0]
  mockSale2: Sale = sales[1]
  addedSales: Array<Sale> = []
  showConfirmation: boolean
  showStartedInfo: boolean
  tmpID: string
  tmpSale: CreateSale
  options: string[] = []
  idControl = new UntypedFormControl()
  groupControl = new UntypedFormControl()

  pickerTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#f7f7f7',
      buttonColor: '#4a90e2'
    },
    dial: {
      dialBackgroundColor: '#4a90e2',
    },
    clockFace: {
      clockFaceBackgroundColor: '#e7e7e7',
      clockHandColor: '#4a90e2',
      clockFaceTimeInactiveColor: '#555'
    }
  }

  fields = [
    {
      id: 'locationId',
      label: 'Sale number',
      type: 'text',
      validators: [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(/^(C|M|F|S)([0-9][0-9]{0,2})$/)
      ],
      value: ''
    },
    {
      id: 'season',
      label: 'Season',
      type: 'select',
      validators: [
        Validators.required
      ],
      options: [],
      value: '20'
    },
    {
      id: 'date',
      label: 'Sale date',
      type: 'date',
      validators: [
        Validators.required
      ],
      value: ''
    },
    {
      id: 'startTime',
      label: 'Start time',
      type: 'time',
      validators: [
        Validators.required
      ],
      value: '09:00 am'
    },
    {
      id: 'source',
      label: 'Source',
      type: 'select',
      validators: [
        Validators.required
      ],
      options: [
        {
          label: 'AWH',
          value: 0
        },
        {
          label: 'OZDE',
          value: 1
        }
      ],
      value: 1
    }
  ]

  orgType = [
    {
      label: 'Buyer',
      value: 0
    },
    {
      label: 'Broker',
      value: 1
    },
    {
      label: 'Both',
      value: 2
    }
  ]

  myGroup: UntypedFormGroup

  saleInputFormGroup: UntypedFormGroup
  orgTypeFormGroup: UntypedFormGroup

  buyers$: Observable<Organisation[]>
  groups$: Observable<String[]>
  groups: string[]
  filteredGroups: string[] = []
  selectOrg: Organisation
  initOrg: Organisation
  selectedType = 'Buyer'
  selectedId = ''
  oldId = ''
  selectedAutoTransfer = ''
  selectedGroup = ''

  constructor (private formBuilder: UntypedFormBuilder, private store: Store<AppState>,
    private store_reducer: Store<ReducerState.AppState>,
    private saleDataService: SaleDataService) {
    const groupOpject: any = {}
    this.populateSeasons()

    const updateMapper = {
      'select': 'change'
    }

    this.fields.forEach(field => {
      groupOpject[field.id] = new UntypedFormControl(field.value, {
        updateOn: updateMapper[field.type] || 'blur',
        validators: [...field.validators]
      })
    })

    this.saleInputFormGroup = formBuilder.group({
      ...groupOpject
    })

  }

  ngOnInit () {
    this.store.select('saleForm').subscribe(sale => {
      this.sale = sale
    })

    this.store.select('organisations').subscribe(organisations => {
      this.organisations = organisations
    })

    this.store_reducer.select('sales').subscribe(addedSales => {
      this.addedSales = addedSales
    })

    this.saleInputFormGroup.valueChanges.subscribe(val => {
      const value = {
        ...val,
        date: moment(val.date).format('YYYY-MM-DD'),
        season: parseInt(val.season, 10)
      }

      this.sale = {
        ...this.sale,
        data: {
          ...this.sale.data,
          ...value
        }
      }
    })

    this.showConfirmation = false
    this.showStartedInfo = false


    this.buyers$ = this.store.pipe(select(selectBrokersAndBuyers))

    this.groups = this.extractGroups()
    this.filteredGroups = this.groups

    this.initOrg = {
      id: '',
      code: '',
      name: '',
      backgroundColor: '',
      foregroundColor: '',
      automaticTransferTo: '',
      group: '',
      type: null
    }
    this.selectOrg = this.initOrg
  }

  onBuyerInputChange(event: any) {
    this.selectedId = event.target.value.toUpperCase()
    this.filterBuyers(this.selectedId)
  }

  onBuyerOptionSelect($event: any) {
    this.selectedId = $event.option.value
    this.filterBuyers(this.selectedId)
  }

  onGroupInputChange(event: any) {
    this.selectedGroup = event.target.value.toUpperCase()
    this.filterGroup()
  }

  onGroupOptionSelect($event: any) {
    this.selectedGroup = $event.option.value
    this.filterGroup()
  }

  populateSeasons () {
    const seasonOptions = []
    for (let i = 18; i <= 28; i++) {
      seasonOptions.push(i)
    }

    this.fields.find(field => field.id === 'season').options = seasonOptions.map(season => {
      return {
        label: season,
        value: season
      }
    })
  }

  get orgname() {
    return this.orgTypeFormGroup.get('orgname').value || ''
  }

  get saleDate() {
    return this.saleInputFormGroup.get('date').value || ''
  }

  get source() {
    const organisationSourceMap = {
      0: 'AWH',
      1: 'OZDE',
      2: 'AWTA'
    }

    return organisationSourceMap[this.saleInputFormGroup.get('source').value]
  }

  submitSale () {
    if (this.saleInputFormGroup.valid || 1 === 1) {
      const { data } = this.sale
      const locationId = data.locationId[0]
      const saleNoString = data.saleNo ? `${data.saleNo}` : data.locationId.replace(locationId, '')
      const { startTime } = data
      const source = data.source || 0

      if (!parseInt(saleNoString, 10)) {
        return
      }

      const saleNo = parseInt(saleNoString, 10)

      const createSalePayload = {
        locationId,
        saleNo,
        season: data.season,
        date: data.date.toLocaleString(),
        startTime,
        source
      }

      let foundSale = false
      let canDelete = true
      let id = null

      for (let j = 0, len = this.addedSales.length; j < len; j++) {

        if (this.addedSales[j].locationId === locationId &&
          this.addedSales[j].season === data.season &&
          this.addedSales[j].saleNo === saleNo
          ) {
            const d1 = new Date(this.addedSales[j].startTime)
            const d2 = new Date(createSalePayload.date)
            if ( d1.getDay() === d2.getDay() && d1.getMonth() === d2.getMonth() && d1.getUTCFullYear() === d2.getUTCFullYear()) {
              foundSale = true
              id = this.addedSales[j].id
              canDelete = this.addedSales[j].canDelete
            }
          }
      }
      if (foundSale === true) {
        if (canDelete === false) {
          this.showStartedInfo = true
          return
        }
        this.tmpID = id
        this.tmpSale = {...createSalePayload}
        this.showConfirmation = true
      } else {
        this.store.dispatch(new Actions.SaleFormActions.SaleFormCreateSale(createSalePayload))
      }

    }
  }

  tryAgain () {
    this.store.dispatch(new Actions.SaleFormActions.SaleFormReEnterSale())
  }

  cancel () {
    this.store.dispatch(new Actions.SaleFormActions.SaleFormCancelSale())
  }

  overwriteSale() {
    this.showConfirmation = false
    this.store.dispatch(new SaleRoomDataDeleted({id: this.tmpID, sale: this.tmpSale}))
  }

  cancelOverwrite() {
    this.showConfirmation = false
  }

  close() {
    this.showStartedInfo = false
  }

  createUpdateOrg() {
    if (this.selectOrg.code === undefined || this.selectOrg.code === null) {
      this.selectOrg.code = ''
    }
    if (this.selectOrg.foregroundColor === undefined || this.selectOrg.foregroundColor === null) {
      this.selectOrg.foregroundColor = ''
    }
    if (this.selectOrg.backgroundColor === undefined || this.selectOrg.backgroundColor === null) {
      this.selectOrg.backgroundColor = ''
    }
    if (this.selectOrg.automaticTransferTo === undefined || this.selectOrg.automaticTransferTo === null) {
      this.selectOrg.automaticTransferTo = ''
    }
    if (this.selectOrg.group === undefined || this.selectOrg.group === null) {
      this.selectOrg.group = ''
    }
    this.store.dispatch(new ShowLoading('Updating organisation'))

    let type = 'A'
    if (this.selectedType === 'Buyer') {
      type = 'E'
    } else if (this.selectedType === 'Both') {
      type = 'B'
    }

    this.saleDataService.updateBrokerAndBuyers(
      this.selectedId ,
      this.selectOrg.code,
      this.selectOrg.name,
      type,
      this.selectOrg.foregroundColor,
      this.selectOrg.backgroundColor,
      this.selectedAutoTransfer,
      this.selectedGroup,
      this.oldId
    )
    .subscribe(
      res => {
        this.store.dispatch(new Dismiss())
        this.store.dispatch(new OrganisationsRequested())
      },
      err => {
        this.store.dispatch(new ShowError({
          hasError: true,
          message: err.error.message
        }))
      },
      () => {
      }
      )
  }

  private extractGroups(): string[] {
    const organisations = this.organisations.brokers.filter((broker) => {
      if (broker.id === broker.group) {
        return broker
      }
    })
    organisations.push.apply(organisations, this.organisations.brokersAndBuyers.filter((brokerAndBuyer) => {
      if (brokerAndBuyer.id === brokerAndBuyer.group) {
        return brokerAndBuyer
      }
    }))
    return [...new Set(organisations.map((organisation) => organisation.id))].sort()
  }

  private filterBuyers(value: string) {
    this.buyers$ = this.store.pipe(select(selectBrokersAndBuyers)).pipe(
      map(option => {
        const arr = option.filter(x => x.id.toLowerCase() === (value.toLowerCase()))
        if ( arr.length >= 1) {
          this.selectOrg = {... arr[0]}
          if ( this.selectOrg.type === 'E') {
            this.selectedType = 'Buyer'
          } else if ( this.selectOrg.type === 'A') {
            this.selectedType = 'Broker'
          } else {
            this.selectedType = 'Both'
          }
          this.oldId = this.selectOrg.id
          this.selectedAutoTransfer = this.selectOrg.automaticTransferTo
          this.selectedGroup = this.selectOrg.group
        } else {
          this.selectedType = 'Buyer'
          this.selectOrg = this.initOrg
          this.oldId = ''
          this.selectedAutoTransfer = ''
          this.selectedGroup = ''
        }
        return of(option.filter(x => x.id.toLowerCase().includes(value.toLowerCase())))
      }),
      concatMap(x => x)
    )
    this.filterGroup()
  }

  private filterGroup() {
    this.filteredGroups = this.groups.filter(group =>
      group.toLowerCase().includes(this.selectedGroup.toLowerCase())
    )
  }
}

import { Pipe, PipeTransform } from '@angular/core'
import { LotRow } from '../lot-row/lot-row.model'
@Pipe({
  name: 'lotrowfilter',
})
export class LotRowFilterPipe implements PipeTransform {
  transform(
    items: LotRow[],
    originalSellingOrganisation: string,
    lotNumber: string,
  ): any[] {
    if (!items) {
      return []
    }

    if (!originalSellingOrganisation && !lotNumber) {
      return items
    }

    originalSellingOrganisation =
      originalSellingOrganisation && originalSellingOrganisation !== ''
        ? originalSellingOrganisation.toLowerCase()
        : null
    lotNumber = lotNumber && lotNumber !== '' ? lotNumber.toLowerCase() : null
    return items.filter(item => {
      if (originalSellingOrganisation && lotNumber) {
        return (
          item.originalSellingOrganisation
            .toLowerCase()
            .includes(originalSellingOrganisation) &&
          item.lotNumber.toLowerCase().replace(/^0+/, '') === (lotNumber)
        )
      }

      if (originalSellingOrganisation) {
        return item.originalSellingOrganisation
          .toLowerCase()
          .includes(originalSellingOrganisation)
      }

      if (lotNumber) {
        return item.lotNumber.toLowerCase().replace(/^0+/, '') === (lotNumber)
      }
    })
  }
}

import { Injectable } from '@angular/core'
import { Actions, createEffect , ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { forkJoin, of } from 'rxjs'
import { Router } from '@angular/router'

import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
  tap,
  switchMap,
} from 'rxjs/operators'

import { AppState } from '../store.interface'
import { Organisations } from '@shared/models/organisations.model'
import { SaleRoomDataLotsRequested } from '../actions/sale-room-data.actions'
import { OrganisationActionTypes, OrganisationsRequested, OrganisationsLoaded } from '../actions/organisations.actions'


import {
  ShowError,
  ShowLoading,
  Dismiss
} from '../actions/data-fetch.actions'

import { SaleRoomDataError } from '../reducers/sale-room-data.reducer'
import { Error } from '@shared/models/error.model'
import { selectOrganisationsLoaded, selectAuthState } from '../selectors'
import { SaleDataService } from '@shared/services/sale-data.service'
import { SaleFormEmitSaleError } from '@store/actions/sale-form.actions'

@Injectable()
export class OrganisationsEffects {

  constructor(
    private actions$: Actions,
    private saleDataService: SaleDataService,
    private store: Store<AppState>,
    private router: Router
  ) {}

  loadSaleRoomData$ = createEffect ( () => this.actions$.pipe(
    ofType<OrganisationsRequested>(OrganisationActionTypes.OrganisationsRequested),
    withLatestFrom(this.store.pipe(select(selectOrganisationsLoaded))),
    filter(([action, saleLoaded]) => {
      return true
    }),
    tap(() => {
      this.showLoader()
    }),
    mergeMap(() =>
      forkJoin(
        this.saleDataService.getBuyers(),
        this.saleDataService.getBrokers(),
        this.saleDataService.getBrokerAndBuyers()
      )
      .pipe(
        catchError(err => {
          const error = <SaleRoomDataError>{
            hasError: true,
            message: `Error requesting sale data from API: ${err}`,
          }

          if (err.status !== 0) {
            this.showError(error)
          }

          return of([[], []])
        }),
        map(
          (data): Organisations => ({
            buyers: data[0],
            brokers: data[1],
            brokersAndBuyers: data[2],
            loaded: true,
            loading: false,
            error: null,
            status: 'null'
          }),
        ),
      ),
    ),
    withLatestFrom(this.store.pipe(select(selectAuthState))),
    filter(data => {
      const [ organisationsData, authState ] = data

      if (!authState.loggedIn) {
        return false
      }

      const { buyers, brokers } = organisationsData
      const brokersFound = !!brokers && brokers.length >= 1
      const buyersFound = !!buyers && buyers.length >= 1
      const dataFound = brokersFound && buyersFound

      if (!dataFound) {
        const error = <Error>{
          hasError: true,
          message: `Could not load organisation data.`,
        }
        this.showError(error)
      }

      return dataFound
    }),
    map(data => {
      this.dismissLoader()
      return new OrganisationsLoaded(data[0])
    }),
  )

  )
  private roomNumber: number
  private saleId: string

  showLoader () {
    this.store.dispatch(new ShowLoading('Fetching organisations...'))
  }

  showUpdateLoader () {
    this.store.dispatch(new ShowLoading('Updating organisations...'))
  }

  showError (error) {
    this.store.dispatch(new ShowError(error))
  }

  dismissLoader () {
    this.store.dispatch(new Dismiss())
  }

  reloadLots () {
    const payload = {
      saleId: this.saleId,
      roomNumber: this.roomNumber
    }

    return new SaleRoomDataLotsRequested(payload)
  }
}

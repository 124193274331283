import { Component, Input, ViewChild, forwardRef, Output, EventEmitter, OnDestroy } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

export interface Option {
  label: string
  value: string | number
  selected?: boolean
}

export const CUSTOM_SELECT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CustomSelectComponent),
  multi: true
}

@Component({
  selector: 'app-custom-select',
  providers: [ CUSTOM_SELECT_VALUE_ACCESSOR ],
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss']
})
export class CustomSelectComponent implements ControlValueAccessor, OnDestroy {
  constructor() {

  }
  @Input() options: Array<Option>
  @Output() blur: EventEmitter<string> = new EventEmitter()
  @ViewChild('dropdown') dropdown
  @ViewChild('select') select
  disabled = false

  public isOpen = false
  private value: string | number = 0
  onChange = (value: string | number) => {}
  onTouch = (value: string | number) => {}

  createClickListener (): void {
    document.addEventListener('click', (event) => {
      if (event.target !== this.dropdown.nativeElement) {
        this.isOpen = false
      }
    })
  }

  destroyClickListener (): void {
    document.removeEventListener('click', null)
  }

  writeValue ( value: any ): void {
    if (value !== undefined) {
      this.value = value
    }
  }

  get currentValue () {
    const currentOption = this.options.find(option => option.value === this.value)
    return currentOption ? currentOption.label : this.value
  }

  registerOnChange(callbackFunction: (value: string) => void): void {
    this.onChange = callbackFunction
  }

  registerOnTouched(callbackFunction: any): void {
    this.onTouch = callbackFunction
  }

  ngOnDestroy() {
    document.removeEventListener('click', null)
  }

  selectOption(option: Option): void {
    this.closeOptions()
    this.value = option.value
    this.onChange(this.value)
  }

  toggleOpen (): void {
    this.isOpen = !this.isOpen
    this.isOpen ? this.createClickListener() : this.destroyClickListener()
  }

  closeOptions (): void {
    this.isOpen = false
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }
}

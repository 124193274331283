import { Component, OnInit } from '@angular/core'
import { trigger, style, animate, transition } from '@angular/animations'

@Component({
  selector: 'app-modal',
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({ opacity: 0 }),
          animate('300ms', style({ opacity: 0.4 }))
        ]),
        transition(':leave', [
          style({ opacity: 0.4 }),
          animate('300ms', style({ opacity: 0 }))
        ])
      ]
    )
  ],
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

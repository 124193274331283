import { Organisation } from '@shared/models/organisation.model'

import {
  OrganisationActionTypes,
  OrganisationsCleared,
  OrganisationsLoaded,
  OrganisationsRequested,
  OrganisationsEdit,
  OrganisationsCancel,
} from '../actions/organisations.actions'
import { SaleDataActionTypes, SaleDataRequested } from '@store/actions/sale-data.actions'

export interface OrganisationsState {
  brokers: Organisation[],
    buyers: Organisation[],
    brokersAndBuyers: Organisation[],
      loaded: boolean,
        loading: boolean,
          error: any,
          status: string,
}

export const initialState: OrganisationsState = {
  brokers: [],
  buyers: [],
  brokersAndBuyers: [],
  loaded: false,
  loading: false,
  error: null,
  status: 'null'
}

export function organisationsReducer(
  state = initialState,
  action:
    | OrganisationsRequested
    | OrganisationsLoaded
    | OrganisationsCleared
    | OrganisationsEdit
    | OrganisationsCancel
    | SaleDataRequested
): OrganisationsState {

  if (!action || !action.type) {
    return initialState
  }

  switch (action.type) {
    case OrganisationActionTypes.OrganisationsRequested:
      return {
        ... state,
        status: 'null'
      }
    case OrganisationActionTypes.OrganisationsLoaded:
      return {
        ...state,
        brokers: action.payload.brokers,
        buyers: action.payload.buyers,
        brokersAndBuyers: action.payload.brokersAndBuyers,
        loaded: true,
        loading: false,
        error: null,
        status: 'null'
      }
    case OrganisationActionTypes.OrganisationsCleared:
      return initialState
    case OrganisationActionTypes.OrganisationsEdit:
      return {
        ...state,
        status: 'in-progress'
      }
    case SaleDataActionTypes.SaleDataRequested:
      return {
        ...state,
        status: 'null'
      }
    case OrganisationActionTypes.OrganisationsCancel:
        return {
          ...state,
          status: 'null'
        }
    default:
      return state
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store'
import { BrokerGroupedByWooltype } from '@shared/models/broker-grouped-by-wooltype.model'
import { BrokerSummary } from '@shared/models/broker-summary.model'
import { DataFetch } from '@shared/models/data-fetch.model'
import { Lot } from '@shared/models/lot.model'
import { Organisations } from '@shared/models/organisations.model'
import { SaleRoomData } from '@shared/models/sale-room-data.model'

import { AuthState } from './reducers/auth.reducer'
import { SearchState } from './reducers/search.reducer'

export const selectSaleRoomDataState = createFeatureSelector<SaleRoomData>(
  'selectedSaleRoom',
)
export const selectDataFetchState = createFeatureSelector<DataFetch>(
  'dataFetch',
)
export const selectAuthState = createFeatureSelector<AuthState>('auth')
export const selectOrganisationsState = createFeatureSelector<Organisations>(
  'organisations',
)
export const selectSearchState = createFeatureSelector<SearchState>('search')

export const isLoggedIn = createSelector(
  selectAuthState,
  auth => auth.loggedIn,
)

export const selectToken = createSelector(
  selectAuthState,
  auth => auth.token,
)

export const isLoggedOut = createSelector(
  isLoggedIn,
  loggedIn => !loggedIn,
)

export const dataFetchHasError = createSelector(
  selectDataFetchState,
  dataFetch => dataFetch.error.hasError,
)

export const dataFetchIsLoading = createSelector(
  selectDataFetchState,
  dataFetch => dataFetch.loading,
)

export const dataFetchLoaded = createSelector(
  selectDataFetchState,
  dataFetch => dataFetch.loaded,
)
export const dataFetchMessage = createSelector(
  selectDataFetchState,
  dataFetch => dataFetch.message,
)

export const dataFetchErrorMessage = createSelector(
  selectDataFetchState,
  dataFetch => dataFetch.error.message,
)

export const selectSaleLoaded = createSelector(
  selectSaleRoomDataState,
  (sale: SaleRoomData) => sale.loaded,
)

export const selectOrganisationsLoaded = createSelector(
  selectOrganisationsState,
  (organisations: Organisations) => organisations.loaded,
)

export const selectSaleId = createSelector(
  selectSaleRoomDataState,
  sale => sale.saleId,
)

export const selectRoomNumber = createSelector(
  selectSaleRoomDataState,
  (sale: SaleRoomData) => sale.roomNumber,
)

export const selectStartTime = createSelector(
  selectSaleRoomDataState,
  (sale: SaleRoomData) => sale.startTime,
)

export const selectSaleRoom = createSelector(
  selectSaleRoomDataState,
  sale => {
    return sale
  },
)

export const selectSaleLots = createSelector(
  selectSaleRoomDataState,
  (sale: SaleRoomData) => sale.lots,
)

export const selectLotCount = createSelector(
  selectSaleLots,
  (lots: Lot[]) => lots.length,
)

export const selectBaleCount = createSelector(
  selectSaleLots,
  (lots: Lot[]) =>
    lots.reduce((accumulator, lot: Lot) => {
      accumulator = accumulator + lot.catalogueInformation.bales
      return accumulator
    }, 0),
)

export const selectSaleBrokerSummary = createSelector(
  selectSaleLots,
  (lots: Lot[]) => {
    const brokers = lots.reduce((accumulator: any, lot: Lot) => {
      let broker = lot.catalogueInformation.sellingOrganisation

      if (lot.transaction) {
        broker = lot.transaction.origin
      }

      accumulator[broker] = accumulator[broker] || []
      accumulator[broker].push(lot)

      return accumulator
    }, {})

    const brokerKeys = Object.keys(brokers)

    const brokerArray: BrokerSummary[] = brokerKeys.map(
      (brokerId): BrokerSummary => {
        const brokerLots = brokers[brokerId]
        const lotCount = brokerLots.length

        const baleCount: number = brokerLots.reduce((accumulator, lot: Lot) => {
          accumulator = accumulator + lot.catalogueInformation.bales
          return accumulator
        }, 0)

        return {
          brokerId,
          lotCount,
          baleCount,
          location: 'L',
        }
      },
    )

    return brokerArray
  },
)

export const selectSaleBrokersGroupedByWoolType = createSelector(
  selectSaleLots,
  (lots: Lot[]) => {
    const brokers = lots.reduce((accumulator: any, lot: Lot) => {
      const broker = `${lot.catalogueInformation.sellingOrganisation}_${
        lot.catalogueInformation.woolTypeGroup
      }`

      accumulator[broker] = accumulator[broker] || []
      accumulator[broker].push(lot)

      return accumulator
    }, {})

    const brokerKeys = Object.keys(brokers)

    const brokerArray: BrokerGroupedByWooltype[] = brokerKeys.map(
      (brokerKey): BrokerGroupedByWooltype => {
        const brokerLots = brokers[brokerKey]
        const lotCount = brokerLots.length
        const ids = brokerKey.split('_')
        const baleCount: number = brokerLots.reduce((accumulator, lot: Lot) => {
          accumulator = accumulator + lot.catalogueInformation.bales
          return accumulator
        }, 0)
        const enabled: boolean = brokerLots.reduce((accumulator, lot: Lot) => {
          if (lot.enabled === false) {
            accumulator = false
          }
          return accumulator
        }, true)

        return {
          brokerId: ids[0],
          lotCount,
          baleCount,
          woolTypeGroup: ids[1],
          enabled
        }
      },
    )

    return brokerArray
  },
)

export const selectBuyers = createSelector(
  selectOrganisationsState,
  (organisations: Organisations) => organisations.buyers,
)

export const selectBrokers = createSelector(
  selectOrganisationsState,
  (organisations: Organisations) => organisations.brokers,
)

export const selectBrokersAndBuyers = createSelector(
  selectOrganisationsState,
  (organisations: Organisations) => organisations.brokersAndBuyers,
)

export const selectSaleTransactions = createSelector(
  selectSaleRoomDataState,
  (sale: SaleRoomData) => sale.transactions,
)

export const selectSearchLocationId = createSelector(
  selectSearchState,
  (state: SearchState) => state.locationId,
)

export const selectSearchDate = createSelector(
  selectSearchState,
  (state: SearchState) => state.date,
)

export const selectSearchVisible = createSelector(
  selectSearchState,
  (state: SearchState) => state.visible,
)
